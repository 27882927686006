import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalCloseBtn({ onClick }) {
	return (
		<Box
			className="center-row"
			position="absolute"
			top="0"
			right="0"
			mt="10px"
			mr="10px"
			zIndex="1000"
		>
			<IconButton
				color="remove"
				onClick={onClick}
				sx={{
					backgroundColor: "#fad7db",
					borderRadius: "50%",
					padding: "2px",
				}}
			>
				<CloseIcon sx={{ fontSize: "20px" }} />
			</IconButton>
		</Box>
	);
}
