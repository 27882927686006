import React, { useState, useEffect, useRef } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import {
	Typography,
	Box,
	Select,
	MenuItem,
	Input,
	InputAdornment,
	Button,
	IconButton,
	Dialog,
} from "@mui/material";
import Title from "../Title";
import { dateToString } from "../../util/Util";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import "./EstimateDetail.css";
import styles from "./EstimateDetail.module.scss";
import InventoryList from "../Inventory/InventoryList";
import Loading from "../Loading";
import EstimatePreviewModal from "./EstimatePreviewModal";
import EstimateDetailSection from "./EstimateDetailSection";
import ExpiredLogo from "../Expired";
import Toggle from "../Util/Toggle";
import NewInventoryConfirmDialog from "../Inventory/NewInventoryConfirmDialog";

export default function EstimateDetail({ caseId }) {
	const [caseInfo, setCaseInfo] = useState(null);

	const [estimate, setEstimate] = useState(null);

	const [requestListId, setRequestListId] = useState();
	const requestListIdRef = useRef(requestListId);

	const [currentEstimate, setCurrentEstimate] = useState();
	const [estimateHistory, setEstimateHistory] = useState([{}]);

	const [inventoryHistoryList, setInventoryHistoryList] = useState([]);
	const inventoryHistoryListRef = useRef(inventoryHistoryList);

	const inventoryRef = useRef();
	const [isLoading, setIsLoading] = useState(true);

	const [isEditabled, setIsEditabled] = useState(false);

	const [isInventoryFold, setIsInventoryFold] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);

	const [estimateType, setEstimateType] = useState(0); // 0 means local, 1 means long distance
	const toggleSectionList = ["Local", "Long Distance"];
	const [showEstimateTypeToggle, setShowEstimateTypeToggle] = useState(false);

	const [hasNewAIInventory, setHasNewAIInventory] = useState(false);
	const bottomRef = useRef();


	useEffect(() => {
		(async () => {
			await getCaseInfo();
			await getEstimateHistory();
			await getEstimateInfo();

			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		let interval = setInterval(() => {
			getCaseInfo();
		}, 20000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		requestListIdRef.current = requestListId;
	}, [requestListId]);

	useEffect(() => {
		inventoryHistoryListRef.current = inventoryHistoryList;
	}, [inventoryHistoryList]);

	const getCaseInfo = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_case_by_id/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		setCaseInfo(res);
		await getInventoryHistory(res.request_id);
	};

	const getInventoryHistory = async (requestId) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_inventory_records/${requestId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);

		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		console.log("Res", res)
		if (inventoryHistoryListRef.current.length !== res.length) {
			console.log("Has a new inventory list")
			setInventoryHistoryList(res);
			// TODO: add more logic to check if the new inventory list is generated
			if (inventoryHistoryListRef.current.length != 0 && res.length > 0 && res[0].created_by === -1 && res[0].id !== requestListIdRef.current && !hasNewAIInventory) {
				setHasNewAIInventory(true);
			}
		}
		if (!requestListIdRef.current && res.length > 0) {
			setRequestListId(res[0].id);
		}
	};

	const getEstimateHistory = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_estimate_history/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		setEstimateHistory(res);
	};

	const getEstimateInfo = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_estimate_by_case_id/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		if (Object.keys(res).length === 0) {
			setIsEditabled(true);
			setShowEstimateTypeToggle(true);
			let estimateTypeTemp = await getEstimateTypeByCaseId(caseId);
			setEstimateType(estimateTypeTemp.estimate_type);
			let estimateTempalte = await getEstimateTemplateEntry(
				estimateTypeTemp.estimate_type
			);
			await createEstimateTemplate(estimateTempalte);
		} else {
			setEstimate(res);
			setRequestListId(res.request_item_list_id);

			await getEstimateEntryValue(res.id);
		}
	};

	const getEstimateTypeByCaseId = async (caseId) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/case/get_estimate_type_by_case_id/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		return res;
	};

	const getEstimateTemplateEntry = async (estimateTypeTemp) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/estimate_entry/${estimateTypeTemp}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		let estimateEntryTemp = [];
		for (let [key, value] of Object.entries(res)) {
			if (key === "Relocation Rate") {
				estimateEntryTemp.unshift({
					section_name: key,
					entry_list: value,
				});
			} else {
				estimateEntryTemp.push({
					section_name: key,
					entry_list: value,
				});
			}
		}
		return estimateEntryTemp;
	};

	// TODO: put the function in backend
	const createEstimateTemplate = async (estimateTempalte) => {
		let jwt = await getAmplifyTokenAsync();

		// Set Initial Value
		for (let section of estimateTempalte) {
			for (let entry of section.entry_list) {
				entry.value = entry.default_value;
			}
		}

		let caseDetail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_case_by_id/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		caseDetail = await caseDetail.json();

		// Initialize Estimate Entry Value
		// let accountInfo = await fetch(
		// 	`${process.env.REACT_APP_AI_MOVING_API}/account/${caseDetail.account_id}`,
		// 	{
		// 		method: "GET",
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Authorization: jwt,
		// 		},
		// 	}
		// );
		// if (!accountInfo.ok || accountInfo.status !== 200)
		// 	throw new Error(accountInfo.statusText);
		// accountInfo = await accountInfo.json();

		let requestInfo = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request/${caseDetail.request_id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!requestInfo.ok || requestInfo.status !== 200)
			throw new Error(requestInfo.statusText);
		requestInfo = await requestInfo.json();

		let movingDetailEntry = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/moving_detail_entry`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!movingDetailEntry.ok || movingDetailEntry.status !== 200)
			throw new Error(movingDetailEntry.statusText);

		movingDetailEntry = await movingDetailEntry.json();

		let requestMovingDetail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_moving_detail/${caseDetail.request_id}`,
			{
				method: "GET",
				headers: {
					"Cotent-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!requestMovingDetail.ok || requestMovingDetail.status !== 200)
			throw new Error(requestMovingDetail.statusText);
		requestMovingDetail = await requestMovingDetail.json();

		let customerInfo = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/user/id/${requestInfo.user_id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!customerInfo.ok || customerInfo.status !== 200)
			throw new Error(customerInfo.statusText);
		customerInfo = await customerInfo.json();
		console.log("Estimate Temp", estimateTempalte);
		for (let section of estimateTempalte) {
			for (let entry of section.entry_list) {
				// if (section.section_name === "Moving Company Info") {
				// 	switch (entry.entry_name) {
				// 		case "Name":
				// 			entry.value = accountInfo.name;
				// 			break;
				// 		case "Address":
				// 			entry.value = accountInfo.address;
				// 			break;
				// 		case "Customer Rep":
				// 			entry.value = accountInfo.admin_name;
				// 			break;
				// 		case "Phone":
				// 			entry.value = accountInfo.phone;
				// 			break;
				// 		case "Direct":
				// 			entry.value = accountInfo.phone;
				// 			break;
				// 		case "Email":
				// 			entry.value = accountInfo.email;
				// 			break;
				// 		default:
				// 			entry.value = "";
				// 	}
				// } else
				if (section.section_name === "Moving From") {
					switch (entry.entry_name) {
						case "Customer Name":
							entry.value = customerInfo.name;
							break;
						case "Address":
							entry.value = requestMovingDetail[18];
							break;
						case "Propeerty Type":
							entry.value = requestMovingDetail[6]
								? movingDetailEntry[2][0].selection_options.find(
									(option) =>
										option.id ===
										parseInt(requestMovingDetail[6])
								).value
								: "";
							break;
						case "Cell Phone":
							entry.value = requestMovingDetail[5];
							break;
						case "Home Phone":
							entry.value = requestMovingDetail[5];
							break;
						case "Email":
							entry.value = requestMovingDetail[4];
							break;
						default:
							entry.value = "";
					}
				} else if (section.section_name === "Moving To") {
					switch (entry.entry_name) {
						case "Customer Name":
							entry.value = customerInfo.name;
							break;
						case "Address":
							entry.value = requestMovingDetail[19];
							break;
						case "Propeerty Type":
							entry.value = requestMovingDetail[11]
								? movingDetailEntry[3][0].selection_options.find(
									(option) =>
										option.id ===
										parseInt(requestMovingDetail[11])
								).value
								: "";
							break;
						case "Cell Phone":
							entry.value = requestMovingDetail[5];
							break;
						case "Home Phone":
							entry.value = requestMovingDetail[5];
							break;
						case "Email":
							entry.value = requestMovingDetail[4];
							break;
						default:
							entry.value = "";
					}
				} else if (section.section_name === "Relocation Details") {
					switch (entry.entry_name) {
						case "Job No.":
							entry.value = caseDetail.id;
							break;
						case "Estimate Date":
							dateToString(
								new Date(parseInt(requestMovingDetail[3]))
							).slice(0, 10);
							break;
						case "Moving Date":
							dateToString(
								new Date(parseInt(requestMovingDetail[3]))
							).slice(0, 10);
							break;
						default:
							entry.value = "";
					}
				}
			}
		}

		setCurrentEstimate(estimateTempalte);

		console.log("Esstimate Template ..... ", estimateTempalte);
	};

	const getEstimateEntryValue = async (estimateId) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_estimate_values_by_estimate_id/${estimateId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		console.log("Temp ", res);
		setCurrentEstimate(res);
		// await getMapInfo(tempValueObject[9], tempValueObject[15]) //get Address from list
	};

	const createNewInventoryList = async () => {
		let jwt = await getAmplifyTokenAsync();
		let inventoryList = inventoryRef.current.getInventoryListWithItems();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/create_inventory_list`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					request_id: caseInfo.request_id,
					inventory_list: inventoryList,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		return res.id;
	};

	const updateEntry = (sectionIndex, entryIndex, value, type) => {
		let temp = [...currentEstimate];
		if (type === "entry_name") {
			temp[sectionIndex].entry_list[entryIndex].entry_name = value;
		} else {
			temp[sectionIndex].entry_list[entryIndex].value = value;
			if (temp[sectionIndex].section_name === "Relocation Rate") {
				let total_value = 0;
				for (let entry of temp[sectionIndex].entry_list) {
					if (entry.entry_name !== "Total Moving Estimate") {
						if (entry.value !== "") {
							total_value += parseFloat(entry.value);
						}
					}
				}
				console.log("total_value", total_value);
				temp[sectionIndex].entry_list[
					temp[sectionIndex].entry_list.length - 1
				].value = total_value.toFixed(2);
			}
		}
		setCurrentEstimate(temp);
	};

	const addNewEntry = (sectionIndex) => {
		let temp = [...currentEstimate];
		if (temp[sectionIndex].section_name === "Relocation Rate") {
			temp[sectionIndex].entry_list.splice(
				temp[sectionIndex].entry_list.length - 1,
				0,
				{
					id: temp[sectionIndex].entry_list.length,
					entry_name: "",
					value: "",
					section_name: temp[sectionIndex].section_name,
					type: estimateType,
				}
			);
		} else {
			temp[sectionIndex].entry_list.push({
				id: temp[sectionIndex].entry_list.length,
				entry_name: "",
				value: "",
				section_name: temp[sectionIndex].section_name,
				type: estimateType,
			});
		}

		setCurrentEstimate(temp);
	};

	const deleteEntry = (sectionIndex, entryIndex) => {
		let temp = [...currentEstimate];
		temp[sectionIndex].entry_list.splice(entryIndex, 1);
		setCurrentEstimate(temp);
	};

	const deleteSection = (sectionIndex) => {
		let temp = [...currentEstimate];
		if (window.confirm("Are you sure you want to delete this section?")) {
			temp.splice(sectionIndex, 1);
			setCurrentEstimate(temp);
		}
	};

	const handleSendNewEstimate = async () => {
		let jwt = await getAmplifyTokenAsync();
		let list_id = await createNewInventoryList();
		console.log({
			case_id: caseInfo.id,
			request_item_list_id: list_id,
			estimate_values: currentEstimate,
		});

		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/create_estimate`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
				body: JSON.stringify({
					case_id: caseInfo.id,
					request_item_list_id: list_id,
					estimate_values: currentEstimate,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		setIsEditabled(false);
		setPreviewModalOpen(false);
		setShowEstimateTypeToggle(false);
		setIsLoading(true);
		await getEstimateHistory();
		await getEstimateInfo();
		setIsLoading(false);
	};

	const estimateTypeToggleOnChange = async (index) => {
		setEstimateType(index);
		setIsLoading(true);
		let estimateTempalte = await getEstimateTemplateEntry(index);
		await createEstimateTemplate(estimateTempalte);
		setIsLoading(false);
	};

	const switchToNewInvenotryList = async (selection) => {
		if (selection) {
			if (!isEditabled) {
				setIsEditabled(true);
				setShowEstimateTypeToggle(true);
			}

			setRequestListId(inventoryHistoryListRef.current[0].id)
			bottomRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}

	if (isLoading) {
		return <Loading />;
	} else {
		let container_style = styles.container_new;
		if (caseInfo.status === 4) {
			container_style = styles.container_booked;
		} else if (!isEditabled) {
			container_style = styles.container_history;
		}
		return (
			<Box className={container_style}>
				{estimate && estimate.valid === 0 && !isEditabled && (
					<ExpiredLogo />
				)}
				<Box
					marginTop="2%"
					marginBottom="10px"
					className="center-row"
					justifyContent="space-between"
					width="90%"
				>
					<Box className={"center-row"}>
						<Title title="Estimate" />
					</Box>
					<Box className={styles.tab_container}>
						<Box
							display={
								caseInfo.status !== 4 &&
									estimateHistory.length > 0
									? "auto"
									: "none"
							}
							className={
								!isEditabled
									? styles.tab_box_active
									: styles.tab_box
							}
							onClick={() => {
								getEstimateInfo();
								setIsEditabled(false);
								setShowEstimateTypeToggle(false);
							}}
						>
							<Typography>History</Typography>
						</Box>
						<Box
							display={caseInfo.status === 4 && "none"}
							className={
								isEditabled
									? styles.tab_box_active
									: styles.tab_box
							}
							onClick={async () => {
								setIsLoading(true);
								getCaseInfo();
								setShowEstimateTypeToggle(true);
								let estimateTypeTemp =
									await getEstimateTypeByCaseId(caseId);
								setEstimateType(estimateTypeTemp.estimate_type);
								let estimateTempalte =
									await getEstimateTemplateEntry(
										estimateTypeTemp.estimate_type
									);
								await createEstimateTemplate(estimateTempalte);
								if (inventoryHistoryList.length > 0) {
									setRequestListId(
										inventoryHistoryList[0].id
									);
								} else {
									setRequestListId(null);
								}

								setIsEditabled(true);
								setIsLoading(false);
							}}
						>
							<Typography>New</Typography>
						</Box>
						{caseInfo.status === 4 && !isEditabled && (
							<Box className={styles.tab_box_active}>
								<Typography>Booked</Typography>
							</Box>
						)}
						{caseInfo.status === 4 && isEditabled && (
							<Box>
								<IconButton
									onClick={async () => {
										setIsLoading(true);
										await getEstimateInfo();
										setIsLoading(false);
										setIsEditabled(false);
										setShowEstimateTypeToggle(false);
									}}
									color="remove"
									variant="contained"
									className="icon_button"
								>
									<CloseIcon sx={{ fontSize: 20 }} />
								</IconButton>
							</Box>
						)}
					</Box>
				</Box>
				{showEstimateTypeToggle && (
					<Box
						justifyContent={"space-between"}
						marginTop="-5px"
						flexDirection="row"
						display="flex"
						width="90%"
					>
						<Typography></Typography>

						<Toggle
							selectedIndex={estimateType}
							toggleList={toggleSectionList}
							onChange={estimateTypeToggleOnChange}
						/>
					</Box>
				)}

				{!isEditabled && (
					<Box
						className="center-row"
						marginTop="5px"
						justifyContent={"space-between"}
						width="90%"
					>
						<Box className="center-row">
							<Typography marginRight="10px">
								{" "}
								Version:{" "}
							</Typography>
							<Select
								value={estimate.id}
								style={{
									width: "250px",
									padding: 1,
								}}
								onChange={async (e) => {
									setIsLoading(true);
									let estimate = estimateHistory.find(
										(estimate) =>
											estimate.id === e.target.value
									);
									setEstimate(estimate);
									await getEstimateEntryValue(e.target.value);
									setIsLoading(false);
								}}
							>
								{estimateHistory.map((estimate) => {
									let state = "";
									if (estimate.valid === 0) {
										state = " - Expired";
									} else if (estimate.valid === 2) {
										state = " - Booked";
									}
									return (
										<MenuItem
											key={estimate.id}
											value={estimate.id}
										>
											{dateToString(
												new Date(estimate.creation_time)
											) + state}
										</MenuItem>
									);
								})}
							</Select>
						</Box>
						<Box display={caseInfo.status > 4 ? "none" : "auto"}>
							<IconButton
								onClick={() => {
									setIsEditabled(true);
								}}
								color="primary"
								variant="contained"
								className="icon_button"
							>
								<DriveFileRenameOutlineTwoToneIcon
									sx={{ fontSize: 20 }}
								/>
							</IconButton>
						</Box>
					</Box>
				)}
				<Box width="100%" marginTop="10px">
					{currentEstimate.length > 0 &&
						currentEstimate.map((section, index) => {
							let isFold = true;
							if (section.section_name === "Relocation Rate") {
								isFold = false;
							}
							if (isEditabled) {
								// if (section.section_name === "Relocation Rate") {
								return (
									<EstimateDetailSection
										key={section.section_name}
										section={section}
										sectionIndex={index}
										isEditabled={true}
										updateEntry={updateEntry}
										addNewEntry={addNewEntry}
										deleteEntry={deleteEntry}
										deleteSection={deleteSection}
										isFold={isFold}
									/>
								);
								// }
							} else {
								// if (section.section_name === "Relocation Rate") {

								return (
									<EstimateDetailSection
										section={section}
										key={section.section_name}
										isEditabled={false}
										isFold={isFold}
									/>
								);
								// }
							}
						})}
				</Box>

				<Typography> ---------- Inventory -----------</Typography>

				{isEditabled && inventoryHistoryList.length > 0 && (
					<Box
						className={
							styles.inventory_list_history_panel_container
						}
					>
						<Box
							width="100%"
							// className={"center-column"}
							justifyContent={"space-around"}
						>
							<Typography
								fontWeight={700}
								marginLeft="5%"
								marginBottom={"20px"}
								marginTop={"10px"}
								color="#02780c"
							>
								Inventory List History
							</Typography>
							<Box
								width="100%"
								className={"center-row"}
								justifyContent={"space-around"}
							>
								<Select
									sx={{
										width: "90%",
										borderRadius: "15px",
										marginRight: "10px",
									}}
									value={requestListId}
									onChange={(e) => {
										setRequestListId(e.target.value);
									}}
								>
									{inventoryHistoryList.map(
										(inventoryDetail) => {
											return (
												<MenuItem
													key={inventoryDetail.id}
													value={inventoryDetail.id}
												>
													<Box
														className={
															styles.inventory_select
														}
													>
														<Typography
															fontWeight={600}
														>
															{
																inventoryDetail.account_name
															}
														</Typography>
														<Box
															className="center-row"
															justifyContent={
																"space-between"
															}
														>
															<Typography
																fontSize={14}
																color="gray"
															>
																{
																	inventoryDetail.user_name
																}
															</Typography>

															<Typography
																fontSize={14}
															>
																{dateToString(
																	new Date(
																		inventoryDetail.creation_time
																	)
																)}
															</Typography>
														</Box>
														{inventoryDetail.annotation && (
															<Typography
																fontWeight={500}
																fontSize={14}
																color="#02780c"
															>
																{
																	inventoryDetail.annotation
																}
															</Typography>
														)}
													</Box>
												</MenuItem>
											);
										}
									)}
								</Select>
							</Box>
						</Box>
					</Box>
				)}

				<Box
					width="95%"
					className="inventory-list-container"
					maxHeight="80%"
					position="relative"
					marginTop={"10px"}
					marginBottom={"20px"}
				>
					<InventoryList
						inventoryListId={requestListId}
						isEditabled={isEditabled}
						isFold={isInventoryFold}
						setIsFold={setIsInventoryFold}
						ref={inventoryRef}
					/>
				</Box>
				{isEditabled && (
					<Button
						// onClick={async () => handleSendNewEstimate()}
						onClick={() => {
							setPreviewModalOpen(true);
						}}
						variant="contained"
						style={{
							width: "100px",
							position: "absolute",
							bottom: "1%",
							left: "50%",
							marginLeft: "-50px",
							marginBottom: "20px",
							borderRadius: "15px",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
						}}
					>
						Preview
					</Button>
				)}

				{previewModalOpen && (
					<EstimatePreviewModal
						isOpen={previewModalOpen}
						setIsOpen={setPreviewModalOpen}
						estimate={currentEstimate}
						inventoryListWithItems={inventoryRef.current.getInventoryListWithItems()}
						handleSubmit={handleSendNewEstimate}
					/>
				)}

				{hasNewAIInventory && (
					<NewInventoryConfirmDialog
						open={hasNewAIInventory}
						setOpen={setHasNewAIInventory}
						switchToNewInvenotryList={switchToNewInvenotryList}
					/>
				)}
				<Box ref={bottomRef} sx={{ marginTop: "80px" }} />
			</Box>
		);
	}
}
