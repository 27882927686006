import "./styles/App.scss";
import { useEffect, useState } from "react";
import { ThemeProvider, Box } from "@mui/material";
import { mainLightTheme } from "./util/Theme";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Logger, Auth, Storage, Hub } from "aws-amplify";
import { Authenticator, AmplifyProvider } from "@aws-amplify/ui-react";
import {
	createBrowserRouter,
	RouterProvider,
	BrowserRouter,
	Navigate,
	Route,
	Routes,
} from "react-router-dom";
import Home from "./routes/Home.jsx";
import { getAmplifyToken, getAmplifyTokenAsync } from "./util/Amplify";
import { component } from "./routes/Login";
import Template from "./routes/Template";

import awsExports from "./aws-exports";

import Root from "./routes/Root";
import ErrorPage from "./routes/ErrorPage";
import RequestForEstimate from "./routes/RequestForEstimate/RequestForEstimate";
import RequestDetailModal from "./routes/RequestForEstimate/RequestDetailModal";
import CaseDashboard from "./routes/CaseDashboard/CaseDashboard";
import CaseDetailModal from "./routes/CaseDashboard/CaseDetailModal";

// Setttings
import InventoryItemManagement from "./routes/Settings/InventoryItemManagement";
import EstimateTemplate from "./routes/Settings/EstimateTemplate";
import UserManagement from "./routes/Settings/UserManagement";

// Media Process Worker Routes
import MediaWorkerRoot from "./routes/MediaWorkerRoot";
import MediaProcessTickets from "./routes/MediaProcessCenter/MediaProcessTickets";
import MediaProcessCompleteTicket from "./routes/MediaProcessCenter/MediaProcessCompleteTicket";
import MediaprocessTicketDetailModal from "./routes/MediaProcessCenter/MediaProcessTicketDetailModal";

// Mover Routes
import MoverRoot from "./routes/MoverRoot";

import { I18n } from "aws-amplify";
I18n.putVocabulariesForLanguage("en", {
	"Create Account": "Mover Registration", // Tab header
	"Create a new account": "New User", // Header text
	"Confirm Password": "Confirm your password", // Confirm Password label
	Email: "Enter your email",
	"Phone Number": "Enter your phone number",
});

Amplify.configure(awsExports);

function App() {
	const [user, setUser] = useState(null);
	const [account, setAccount] = useState(null);

	const getUser = async (user) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/user/${user.attributes.email}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);

		if (!res.ok || res.status !== 200) throw new Error(res.statusText);

		res = await res.json();
		if (Object.keys(res).length === 0) {
			// let res = await fetch(
			// 	`${process.env.REACT_APP_AI_MOVING_API}/user/createNew`,
			// 	{
			// 		method: "POST",
			// 		body: JSON.stringify({
			// 			name: user.attributes.name,
			// 			email: user.attributes.email,
			// 			type: 3,
			// 			account: -1,
			// 		}),
			// 		headers: {
			// 			"Content-Type": "application/json",
			// 			Authorization: jwt,
			// 		},
			// 	}
			// );
			// if (!res.ok || res.status !== 200) throw new Error(res.statusText);
			// res = await res.json();
			// setUser(res);
			window.alert("You are not authorized to access the system");
		} else {
			setUser(res);
		}

		// if (res.account_id !== 0 && res.account_id !== -1) {
		// 	await getAccount(res.account_id);
		// }
		// await localStorage.setItem("userId", JSON.stringify(res["id"]));
	};

	const getAccount = async (account_id) => {
		let jwt = await getAmplifyTokenAsync();

		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/account/${account_id}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
				},
			}
		);

		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		setAccount(res);
		await localStorage.setItem("accountId", JSON.stringify(res["id"]));
		console.log("Account", res);
	};

	useEffect(() => {
		let hubListenerCancelToken = Hub.listen(
			"auth",
			({ payload: { event, data } }) => {
				switch (event) {
					case "signIn":
						getUser(data);
						break;
					case "signOut":
						setUser(null);
						setAccount(null);
						localStorage.removeItem("userId");
						localStorage.removeItem("accountId");
						break;
					// case "signUp":
					// 	getUser(data);
					// 	console.log("user signed up");
					// 	break;
					// case "signIn_failure":
					//   console.log("user sign in failed");
					//   break;
					// case "configured":
					//   console.log("the Auth module is configured");
					//   break;
					default:
						console.log("default");
				}
			}
		);
	});

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				getUser(user);
			})
			.catch((err) => console.log(err));
	}, []);

	const adminRouter = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			errorElement: <ErrorPage />,

			children: [
				{ index: true, element: <RequestForEstimate /> },
				{
					path: "request-for-estimate/",
					element: <RequestForEstimate />,

					children: [
						{
							path: "request-detail/:requestId",
							element: <RequestDetailModal />,
						},
					],
				},

				{
					path: "case-dashboard",
					element: <CaseDashboard />,
					children: [
						{
							path: "case-detail/:caseId",
							element: <CaseDetailModal />,
						},
					],
				},
				{ path: "iventory-item", element: <InventoryItemManagement /> },
				{ path: "estimate-template", element: <EstimateTemplate /> },
				{ path: "user-management", element: <UserManagement /> },
			],
		},
	]);

	const mediaWorkerRouter = createBrowserRouter([
		{
			path: "/",
			element: <MediaWorkerRoot />,
			errorElement: <ErrorPage />,

			children: [
				{ index: true, element: <MediaProcessTickets /> },
				{
					path: "media-tickets",
					element: <MediaProcessTickets />,

					children: [
						{
							path: "ticket/:ticketId/:requestId",
							element: <MediaprocessTicketDetailModal />,
						},
					],
				},
				{
					path: "completed-tickets",
					element: <MediaProcessCompleteTicket />,

					children: [
						{
							path: "ticket/:ticketId/:requestId",
							element: <MediaprocessTicketDetailModal />,
						},
					],
				},
			],
		},
	]);

	const moverRouter = createBrowserRouter([
		{
			path: "/",
			element: <MoverRoot />,
		},
	]);

	const loadRouter = (userInfo) => {
		// let user = await Auth.currentAuthenticatedUser();
		// let jwt = await getAmplifyTokenAsync();
		// let userData = await fetch(
		// 	`${process.env.REACT_APP_AI_MOVING_API}/user/${user.attributes.email}`,
		// 	{
		// 		method: "GET",
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Authorization: jwt,
		// 		},
		// 	}
		// );
		// if (!userData.ok || userData.status !== 200)
		// 	throw new Error(userData.statusText);
		// userData = await userData.json();
		// if (userData.active === 0) {
		// 	return <Box>Sorry, you unable to access the system now</Box>;
		// }
		// console.log("userInfo", userData);
		let router;
		if (user.user_type === 1 || user.account_id === 0) {
			router = moverRouter;
		}
		if (user.user_type === 0) {
			router = adminRouter;
		}
		if (user.user_type === 2) {
			router = mediaWorkerRouter;
		}

		return <RouterProvider router={router} />;
	};

	return (
		<AmplifyProvider>
			<Authenticator
				components={component}
				hideSignUp={true}
				loginMechanisms={["email"]}
			>
				{({ signOut, signInUser }) => {
					if (user) {
						return (
							<ThemeProvider theme={mainLightTheme}>
								{loadRouter()}
								{/* <RouterProvider router={adminRouter} /> */}
							</ThemeProvider>
						);
					}
				}}
			</Authenticator>
		</AmplifyProvider>
	);
}

export default App;
