import { Link, Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Title from "../../components/Title";
import { useState, useEffect, useMemo } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import CaseTable from "../../components/Case/CaseTable";
import Loading from "../../components/Loading";
import CaseDashboardTab from "../../components/Case/CaseDashboardTab";
export default function CaseDashboard() {
	const [isLoading, setIsLoading] = useState(true);

	const [caseStatus, setCaseStatus] = useState([]);
	const [currentCaseTab, setCurrentCaseTab] = useState(1);

	const [accountCases, setAccountCases] = useState({});

	const [currentStatusCases, setCurrentStatusCases] = useState([]);

	const [notification, setNotification] = useState({});
	const [unviewedCaseCount, setUnviewedCaseCount] = useState(0);

	const caseTableColumns = useMemo(() => [
		{
			Header: "Id",
			accessor: "id",
		},
		{
			Header: "Name",
			accessor: "user_name",
		},
		{
			Header: "From",
			accessor: "request.moving_from",
		},
		{
			Header: "To",
			accessor: "request.moving_to",
		},
		{
			Header: "Moving Date",
			accessor: "request.moving_date",
		},
		{
			Header: "Updated Time",
			accessor: "updated_time",
		},
		{
			Header: "Created Time",
			accessor: "creation_time",
		},
		{ Header: "Notification", accessor: "notification" },
		{ Header: "New", accessor: "has_company_viewed" },
	]);

	const get_cases_by_status = async (statusId) => {
		let jwt = await getAmplifyTokenAsync();
		let cases = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_cases_by_account_id_and_status/${statusId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!cases.ok || cases.status !== 200)
			throw new Error(cases.statusText);
		cases = await cases.json();
		setCurrentStatusCases(cases);
	};

	const get_case_status = async () => {
		let jwt = await getAmplifyTokenAsync();
		let status = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_case_status`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!status.ok || status.status !== 200)
			throw new Error(status.statusText);
		status = await status.json();
		setCurrentCaseTab(status[0].id);
		setCaseStatus(status);
		await get_cases_by_status(status[0].id);
	};

	const get_notification = async () => {
		let start = new Date();
		let jwt = await getAmplifyTokenAsync();
		let notification = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_total_notification`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!notification.ok || notification.status !== 200)
			throw new Error(notification.statusText);
		notification = await notification.json();
		setNotification(notification);
	};

	const get_unviewed_case_count = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_unviewed_case_count`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		setUnviewedCaseCount(res);
	};

	const handleTabChange = async (tabIndex) => {
		console.log(tabIndex);
		// setIsLoading(true);
		await get_cases_by_status(tabIndex);
		setCurrentCaseTab(tabIndex);
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			get_notification();
			get_unviewed_case_count();
			await get_case_status();

			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		let interval = setInterval(() => {
			get_notification();
			get_unviewed_case_count();
			get_cases_by_status(currentCaseTab);
		}, 10000);
		return () => clearInterval(interval);
	});

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box className="content-container-wrap">
			<Box className="content-container">
				<Box className="center-row" height="6%" marginBottom={"15px"}>
					<Title title="Cases Dashboard" />
				</Box>
				<Box height="8%" className="center-row" width="100%">
					<CaseDashboardTab
						caseTabs={caseStatus}
						currentTab={currentCaseTab}
						setCurrentTab={handleTabChange}
						notification={notification}
						unviewedCaseCount={unviewedCaseCount}
					/>
				</Box>
				<Box
					display="flex"
					flexDirection="row"
					flexWrap={"wrap"}
					height="85%"
					width="94%"
					marginLeft="3%"
					marginRight="3%"
					justifyContent="flex-start"
					alignContent="start"
					marginBottom={"20px"}
					overflow={"scroll"}
				>
					<Box
						className="center-column"
						width="100%"
						style={{
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
						marginTop="20px"
						marginBottom="20px"
					>
						{currentStatusCases && (
							<CaseTable
								columns={caseTableColumns}
								data={currentStatusCases}
							/>
						)}
					</Box>
				</Box>
			</Box>
			<Outlet />
		</Box>
	);
}
