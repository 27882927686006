import { Typography } from "@mui/material";

export default function Title({ title }) {
	return (
		<Typography
			variant="h5"
			fontWeight="600"
			style={{
				textShadow: "0 0 3em #3b82f6",
			}}
			position="sticky"
		>
			{title}
		</Typography>
	);
}
