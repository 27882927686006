import {
	Box,
	Select,
	FormControl,
	MenuItem,
	Typography,
	TextField,
	Button,
	IconButton,
	Icon,
} from "@mui/material";
import styles from "./Settings.module.scss";
import Title from "../../components/Title";
import { useState, useEffect } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import { getAccountId } from "../../util/Util";
import { DefaultDataGrid } from "../../components/DataGrid/StyledDataGrid";
import {
	GridRowModes,
	GridActionsCellItem,
	GridRowEditStopReasons,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DoneGif from "../../components/DoneGif";
import { API, Auth } from "aws-amplify";
import AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import UserManagementCreateNew from "./UserManagementCreateNew";
import Toggle from "../../components/Util/Toggle";
import PauseCircleFilledTwoToneIcon from "@mui/icons-material/PauseCircleFilledTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import NotStartedTwoToneIcon from "@mui/icons-material/NotStartedTwoTone";
import { getUserData } from "../../util/Util";
AWS.config.update({
	region: "us-east-2",
	accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default function UserManagement() {
	const [viewerType, setViewerType] = useState(-1); // only admin, 0 can view this page

	const [userList, setUserList] = useState([]);
	const [userTypeList, setUserTypeList] = useState([]);

	const [externalMoverList, setExternalMoverList] = useState([]);
	const [moverPairStatusObj, setMoverPairStatusObj] = useState({});

	const [isLoading, setIsLoading] = useState(true);
	const [rowModesModel, setRowModesModel] = useState({});
	const [openDoneGif, setOpenDoneGif] = useState(false);
	const [showCreateNew, setShowCreateNew] = useState(false);

	const toggleSectionList = ["Company", "External Movers"];
	const [selectedToggleIndex, setSelectedToggleIndex] = useState(0);

	const userDataGridColumn = [
		{
			field: "id",
			headerName: "ID",
			minWidth: 50,
			flex: 1,
			headerAlign: "center",
			align: "center",
			editable: false,
			cellClassName: "uneditable-cell",
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: true,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
			cellClassName: "uneditable-cell",
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: true,
		},
		{
			field: "user_type",
			headerName: "Role",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: true,
			type: "singleSelect",
			valueOptions: userTypeList,
		},
		{
			field: "active",
			headerName: "Active",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			type: "boolean",
			editable: true,
		},
		{
			field: "status",
			headerName: "Pw Status",
			minWidth: 100,
			flex: 2,
			editable: false,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				let bg = "#a6a8a5";
				let fs = "12px";
				if (params.value === "CONFIRMED") {
					bg = "#57b041";
				} else if (params.value === "RESET_REQUIRED") {
					bg = "#e07c0b";
					fs = "10px";
				} else if (params.value === "FORCE_CHANGE_PASSWORD") {
					bg = "#5a81ed";
					fs = "10px";
				}
				return (
					<Box
						sx={{
							backgroundColor: bg,
							color: "#F4F4F4",
							fontSize: "12px",
							borderRadius: "10px",
							padding: "6px 8px",
							fontWeight: "600",
							fontSize: fs,
						}}
					>
						{params.value}
					</Box>
				);
			},
		},
		{
			field: "actions",
			type: "actions",
			// headerName: "Actions",
			minWidth: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				} else {
					return [
						<GridActionsCellItem
							icon={<EditIcon />}
							label="Edit"
							className="textPrimary"
							onClick={() => handleEditClick(id)}
							color="inherit"
						/>,
					];
				}
			},
		},
	];

	const moverDataGridColumn = [
		{
			field: "id",
			headerName: "ID",
			minWidth: 50,
			flex: 1,
			headerAlign: "center",
			align: "center",
			editable: false,
			cellClassName: "uneditable-cell",
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
			cellClassName: "uneditable-cell",
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
		},
		{
			field: "pair_info.status",
			headerName: "Status",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
			valueGetter: (params) => {
				return moverPairStatusObj[params.row.pair_info.status];
			},
			renderCell: (params) => {
				let status = params.row.pair_info.status;
				console.log("status", status);
				let bg = "#a6a8a5";
				let fs = "12px";
				if (status === 1) {
					bg = "#57b041";
				} else if (status === 2) {
					bg = "#5a81ed";
					fs = "10px";
				} else {
					bg = "#e07c0b";
					fs = "10px";
				}
				console.log("fz", fs);
				return (
					<Box
						key={"status" + params.row.pair_info.id}
						sx={{
							backgroundColor: bg,
							color: "#F4F4F4",
							fontSize: fs,
							borderRadius: "10px",
							padding: "6px 8px",
							fontWeight: "600",
						}}
					>
						{moverPairStatusObj[status]}
					</Box>
				);
			},
		},
		{
			fieldName: "action",
			// headerName: "Action",
			minWidth: 100,
			flex: 1,
			headerAlign: "center",
			align: "center",
			editable: false,
			renderCell: (params) => {
				let status = params.row.pair_info.status;
				let pairId = params.row.pair_info.id;
				if (status === 1) {
					return (
						<IconButton
							key={"button" + params.row.pair_info.id}
							onClick={async () =>
								await handlePairStatusUpdate(pairId, 4)
							}
							color="error"
						>
							<PauseCircleFilledTwoToneIcon fontSize="large" />
						</IconButton>
					);
				} else if (status === 2) {
					return (
						<IconButton
							key={"button" + params.row.pair_info.id}
							onClick={async () =>
								await handlePairStatusUpdate(pairId, 1)
							}
							color="success"
						>
							<CheckCircleTwoToneIcon fontSize="large" />
						</IconButton>
					);
				} else if (status === 4) {
					return (
						<IconButton
							key={"button" + params.row.pair_info.id}
							onClick={async () =>
								await handlePairStatusUpdate(pairId, 1)
							}
							color="primary"
						>
							<NotStartedTwoToneIcon fontSize="large" />
						</IconButton>
					);
				} else {
					return <></>;
				}
			},
		},
	];

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			let userData = await getUserData();
			setViewerType(userData.user_type);
			if (userData.user_type === 0) {
				await getUserType();
				await getUserList();
			} else {
				setIsLoading(false);
			}
		})();
	}, []);

	const getUserType = async () => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let userTypeObj = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/user_type`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!userTypeObj.ok || userTypeObj.status !== 200)
			throw new Error(userTypeObj.statusText);
		userTypeObj = await userTypeObj.json();

		let userTypeObjTemp = [];
		for (let type of userTypeObj.user_type) {
			if (type.user_type !== "Customer") {
				userTypeObjTemp.push({
					value: type.id,
					label: type.user_type,
				});
			}
		}
		setUserTypeList(userTypeObjTemp);
		setIsLoading(false);
	};

	const getUserList = async () => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let accountId = await getAccountId();
		let userListObj = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/users/${accountId}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!userListObj.ok || userListObj.status !== 200)
			throw new Error(userListObj.statusText);
		userListObj = await userListObj.json();
		userListObj = userListObj.users;
		let userAccountId = await getAccountId();
		let userInfoFromCognito = await getUserFromCognito(userAccountId);
		let userInfoObj = {};
		for (let user of userInfoFromCognito) {
			let user_email = user.Attributes.find(
				(e) => e.Name === "email"
			).Value.toLowerCase();
			userInfoObj[user_email] = user;
		}

		for (let user of userListObj) {
			let userCognito = userInfoObj[user["email"].toLowerCase()];
			if (userCognito) {
				user["created_time"] = userCognito.UserCreateDate;
				user["status"] = userCognito.UserStatus;
			} else {
				user["created_time"] = "N/A";
				user["status"] = "N/A";
			}
		}

		setUserList(userListObj);
		setIsLoading(false);
	};

	const getMoverList = async () => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/user/external_movers`,
			{
				methods: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();

		await getPairStatus();
		setExternalMoverList(res);
	};

	const getPairStatus = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/mover/company_pair_status_list`,
			{
				methods: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		res = res.data;
		let res_obj = {};
		for (let row of res) {
			res_obj[row.id] = row.status;
		}
		setMoverPairStatusObj(res_obj);
	};

	const handlePairStatusUpdate = async (pairId, status) => {
		console.log("Click", pairId);
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/mover/company_pair/update`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					pair_id: pairId,
					update_status: status,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		await getMoverList();
		setIsLoading(false);

		setOpenDoneGif(true);

		setTimeout(() => {
			setOpenDoneGif(false);
		}, 2000);
	};

	const getUserFromCognito = async (userAccountId) => {
		let provider = new AWS.CognitoIdentityServiceProvider();

		let res = await provider
			.listUsersInGroup({
				UserPoolId: awsmobile.aws_user_pools_id,
				GroupName: userAccountId.toString(),
			})
			.promise();
		return res.Users;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const handleEditClick = (id) => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	};

	const handleSaveClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleCancelClick = (id) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = userList.find((row) => row.id === id);
		if (editedRow.isNew) {
			setUserList(userList.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = async (newRow) => {
		const updatedRow = { ...newRow, isNew: false };

		const jwt = await getAmplifyTokenAsync();
		const accountId = await getAccountId();
		console.log("newRow", newRow);
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/update_user`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					...newRow,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		let oldRow = userList.find((row) => row.id === newRow.id);
		if (oldRow.active !== newRow.active) {
			let cognito = new AWS.CognitoIdentityServiceProvider();
			if (newRow.active) {
				await cognito
					.adminEnableUser({
						UserPoolId: awsmobile.aws_user_pools_id,
						Username: newRow.email,
					})
					.promise();
			} else {
				await cognito
					.adminDisableUser({
						UserPoolId: awsmobile.aws_user_pools_id,
						Username: newRow.email,
					})
					.promise();
				// await cognito
				// 	.adminUserGlobalSignOut({
				// 		UserPoolId: awsmobile.aws_user_pools_id,
				// 		Username: newRow.email,
				// 	})
				// 	.promise();
			}
		}
		setUserList(
			userList.map((row) => (row.id === newRow.id ? updatedRow : row))
		);

		setOpenDoneGif(true);

		setTimeout(() => {
			setOpenDoneGif(false);
		}, 2000);

		return updatedRow;
	};

	const addUserToGroup = async (newUser) => {
		let accountId = await getAccountId();

		let cognito = new AWS.CognitoIdentityServiceProvider();
		try {
			cognito
				.adminAddUserToGroup({
					UserPoolId: awsmobile.aws_user_pools_id,
					Username: newUser.email,
					GroupName: "" + accountId,
				})
				.promise();
		} catch (err) {
			alert(err.message);
			return;
		}
	};

	const handleCreateNewUser = async (newUser) => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let accountId = await getAccountId();

		let cognito = new AWS.CognitoIdentityServiceProvider();
		try {
			let cogRes = cognito.adminCreateUser({
				UserPoolId: awsmobile.aws_user_pools_id,
				Username: newUser.email,
				DesiredDeliveryMediums: ["EMAIL"],
				ForceAliasCreation: false,
				UserAttributes: [
					{
						Name: "email",
						Value: newUser.email,
					},
					{
						Name: "email_verified",
						Value: "True",
					},
				],
			});

			cogRes = await cogRes.promise();
		} catch (err) {
			alert(err.message);
			return;
		}

		let res = fetch(`${process.env.REACT_APP_AI_MOVING_API}/update_user`, {
			method: "POST",
			headers: {
				Authorization: jwt,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				...newUser,
				account_id: accountId,
			}),
		});

		setOpenDoneGif(true);
		getUserList();
		setTimeout(() => {
			cognito
				.adminAddUserToGroup({
					UserPoolId: awsmobile.aws_user_pools_id,
					Username: newUser.email,
					GroupName: "" + accountId,
				})
				.promise()
				.then(() => {
					getUserList();
				});
		}, 5000);
		setTimeout(() => {
			setOpenDoneGif(false);
		}, 2000);

		setIsLoading(false);
		setShowCreateNew(false);
	};

	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleToggleChange = async (index) => {
		setSelectedToggleIndex(index);
		if (index === 0) {
			await getUserList();
		} else {
			await getMoverList();
		}
		setIsLoading(false);
	};
	if (isLoading) {
		return <> </>;
	} else if (viewerType !== 0) {
		return (
			<Box className="center-row" width="100%" marginTop="100px">
				<Typography variant="h6" fontWeight="600">
					You are not authorized to view this page.
				</Typography>
			</Box>
		);
	}

	return (
		<Box className="content-container-wrap">
			<Box className="content-container">
				{/* <Box
					position="absolute"
					left="2%"
					top="0px"
					height="80px"
					className="center-row"
				>
					<Toggle
						selectedIndex={selectedToggleIndex}
						toggleList={toggleSectionList}
						onChange={handleToggleChange}
					/>
				</Box> */}

				<Box height="80px" className="center-row">
					<Title title="User Management" />
					{!showCreateNew && selectedToggleIndex === 0 && (
						<Box
							position="absolute"
							right="2%"
							top="20px"
							height="80px"
							className="center-row"
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setShowCreateNew(true)}
							>
								New User
							</Button>
						</Box>
					)}
					{showCreateNew && selectedToggleIndex === 0 && (
						<UserManagementCreateNew
							userTypeList={userTypeList}
							handleCreateNewUser={handleCreateNewUser}
							hideModal={() => setShowCreateNew(false)}
						/>
					)}
				</Box>

				{/* {selectedToggleIndex === 1 && (
					<Box
						position="absolute"
						right="2%"
						top="20px"
						height="80px"
						className="center-row"
						padding="10px"

					>
						<Button
							variant="contained"
							color="primary"
							// onClick={() => setShowCreateNew(true)}
						>
							Company Code: CXAFG
						</Button>
					</Box>
				)} */}
				<Box
					className="scroll-container center-column"
					height="calc(100% - 80px)"
				>
					<DoneGif isOpen={openDoneGif} />

					<Box width="96%" className="center-column" height="92%">
						{selectedToggleIndex === 0 && (
							<DefaultDataGrid
								rows={userList}
								columns={userDataGridColumn}
								isLoading={isLoading}
								editMode="row"
								rowModesModel={rowModesModel}
								handleRowEditStop={handleRowEditStop}
								onRowModesModelChange={
									handleRowModesModelChange
								}
								processRowUpdate={processRowUpdate}
								onCellDoubleClik={(params, event) => {
									console.log("here", params);
								}}
								hideFooterSelectedRowCount
								getRowClassName={(params) =>
									params.indexRelativeToCurrentPage % 2 === 0
										? "even"
										: "odd"
								}
							/>
						)}
						{selectedToggleIndex === 1 && !isLoading && (
							<DefaultDataGrid
								rows={externalMoverList}
								columns={moverDataGridColumn}
								isLoading={isLoading}
								hideFooterSelectedRowCount
								getRowClassName={(params) =>
									params.indexRelativeToCurrentPage % 2 === 0
										? "even"
										: "odd"
								}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
