import { Dialog, Box, Typography, Button, IconButton } from "@mui/material";
import styles from "./EstimatePreviewModal.module.scss";
import { useState, useEffect } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import Loading from "../Loading";
import EstimateDetailSection from "./EstimateDetailSection";
import ModalCloseBtn from "../ModalCloseBtn";

export default function EstimatePreviewModal({
	isOpen,
	setIsOpen,
	estimate,
	inventoryListWithItems,
	handleSubmit,
}) {
	const [inventoryPool, setInventoryPool] = useState([]);

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false);
			}}
			maxWidth="lg"
			fullWidth
			sx={{
				"& .MuiDialog-paper": {
					position: "absolute",
					backgroundColor: "rgba(255,255,255,0.8)",
					borderRadius: "20px",
					height: "96vh",
					flexDirection: "row",
					display: "flex",
					justifyContent: "space-evenly",
					alignItems: "center",
					backdropFilter: "blur(10px)",
				},
			}}
		>
			<Box className={styles.content_contaier}>
				<ModalCloseBtn onClick={() => setIsOpen(false)} />
				<Box className="center-column">
					<Typography fontWeight="800" fontSize="24px" padding="1%">
						Estimate Preview
					</Typography>
				</Box>

				<Box className={styles.preview_info_container}>
					{estimate.map((section, sectionIndex) => {
						return (
							<Box width="50%" key={sectionIndex}>
								<EstimateDetailSection
									section={section}
									isEditabled={false}
								/>
							</Box>
						);
					})}
				</Box>

				<Box
					className={styles.preview_info_container}
					marginBottom="80px"
				>
					<Box width="100%" className="center-row">
						<Typography fontWeight={"600"}>
							Inventory List
						</Typography>
					</Box>
					<Box
						className="center-row"
						justifyContent={"flex-end"}
						alignItems={"start"}
						paddingLeft="5%"
						paddingRight="5%"
						paddingBottom={"5px"}
						width="100%"
					>
						<Box
							className="center-row"
							bgcolor={"#F5F5F5"}
							padding="6px"
							paddingLeft="10px"
							paddingRight="10px"
							borderRadius="20px"
							boxShadow="0px 0px 5px 0px rgba(0,0,0,0.15)"
							marginRight="10px"
						>
							<Typography
								fontSize={14}
								fontWeight="500"
								paddingRight="5px"
								color="gray"
							>
								Items:
							</Typography>
							<Typography
								fontSize={14}
								fontWeight="600"
								color="green"
							>
								{inventoryListWithItems.reduce((acc, room) => {
									return (
										acc +
										room.items.reduce((acc, item) => {
											return (
												acc + parseInt(item.quantity)
											);
										}, 0)
									);
								}, 0)}
							</Typography>
						</Box>
						<Box
							className="center-row"
							bgcolor={"#F5F5F5"}
							padding="6px"
							borderRadius="20px"
							paddingLeft="10px"
							paddingRight="10px"
							boxShadow="0px 0px 5px 0px rgba(0,0,0,0.15)"
						>
							<Typography
								fontSize={14}
								fontWeight="500"
								paddingRight="5px"
								color="gray"
							>
								Cu Ft:
							</Typography>
							<Typography
								fontSize={14}
								fontWeight="600"
								color="green"
							>
								{inventoryListWithItems.reduce((acc, room) => {
									return (
										acc +
										room.items.reduce((acc, item) => {
											return (
												acc +
												item.quantity * item.cubic_ft
											);
										}, 0)
									);
								}, 0)}
							</Typography>
						</Box>
					</Box>
					{inventoryListWithItems.map((room, roomIndex) => {
						return (
							<Box
								key={roomIndex}
								className={styles.inventory_room_container}
							>
								<Box className="center-row" padding="2%">
									<Typography>{room.room_label}</Typography>
								</Box>

								<Box
									className="center-row"
									width="90%"
									padding="1%"
									backgroundColor="#d6d6d6"
								>
									<Box width="20%" className="center-row">
										<Typography>QTY</Typography>
									</Box>
									<Box
										width="60%"
										borderLeft="1px solid black"
										borderRight="1px solid black"
										className="center-row"
									>
										<Typography>Item</Typography>
									</Box>
									<Box width="20%" className="center-row">
										<Typography>Cu Ft</Typography>
									</Box>
								</Box>
								{room.items.map((item, itemIndex) => {
									let bgColor =
										itemIndex % 2 === 0
											? "#f5f5f5"
											: "#ffffff";
									return (
										<Box
											key={itemIndex}
											className="center-row"
											alignItems="flex-end"
											width="90%"
											paddingTop="5px"
											paddingBottom="5px"
											bgcolor={bgColor}
										>
											<Box
												width="20%"
												className="center-row"
											>
												<Typography>
													{item.quantity}
												</Typography>
											</Box>
											<Box width="60%">
												<Typography>
													{item.item_name}
												</Typography>
											</Box>
											<Box
												width="20%"
												className="center-row"
												justifyContent={"space-evenly"}
											>
												<Typography>
													{item.cubic_ft}
												</Typography>
											</Box>
										</Box>
									);
								})}
							</Box>
						);
					})}
				</Box>

				<Button
					onClick={async () => handleSubmit()}
					// onClick={() => {
					// 	setPreviewModalOpen(true);
					// }}
					variant="contained"
					style={{
						width: "200px",
						position: "absolute",
						bottom: "1%",
						left: "50%",
						marginLeft: "-100px",
						marginBottom: "20px",
						borderRadius: "15px",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					}}
				>
					Send Estimate
				</Button>
			</Box>
		</Dialog>
	);
}
