import React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, params } from "react-router-dom";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import MediaRoomDetail from "../Request/MediaRoomDetail";
import Loading from "../Loading";
import Title from "../Title";
import { Box } from "@mui/system";
export default function MediaProcessMediaDetail({ requestId }) {
	const [mediaList, setMediaList] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const getMediaList = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_media_list/${requestId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		let resArray = [];
		for (let roomName in res) {
			let requestedMediaList = res[roomName];
			let resMediaList = [];
			for (let requestedMedia of requestedMediaList) {
				let mediaObj = {};
				mediaObj["id"] = requestedMedia.id;
				mediaObj["label"] = requestedMedia.media_label;
				mediaObj["comment"] = requestedMedia.note;
				mediaObj["uri"] = requestedMedia.local_uri;
				mediaObj["localUri"] = requestedMedia.local_uri;
				mediaObj["mediaType"] = requestedMedia.media_type;
				mediaObj["duration"] = requestedMedia.duration;
				mediaObj["s3Uri"] = requestedMedia.s3_uri;
				mediaObj["createdTime"] = requestedMedia.created_time;
				mediaObj["createdBy"] = requestedMedia.created_by;
				mediaObj["createdByName"] = requestedMedia.created_by_name;

				resMediaList.push(mediaObj);
			}

			resArray.push({ roomLabel: roomName, medias: resMediaList });
		}
		setMediaList(resArray);
	};

	useEffect(() => {
		(async () => {
			await getMediaList();
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<Box
			className="center-column"
			height="100%"
			w="100%"
			borderRadius="20px"
			sx={{
				backgroundImage: `url(${require("../../assets/mountain1.jpeg")})`,
			}}
		>
			<Box
				className="center-column"
				justifyContent={"flex-start"}
				style={{
					height: "98%",
					width: "100%",
					overflow: "scroll",
				}}
			>
				<Title title="Room Media Lists" />
				{mediaList.map((mediaRoom, index) => (
					<MediaRoomDetail
						key={index}
						index={index}
						room={mediaRoom}
					/>
				))}
			</Box>
		</Box>
	);
}
