import { Typography, Box, IconButton, Button } from "@mui/material";
import styles from "./MoverRoot.module.scss";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";

export default function MoverRoot() {
	const navigate = useNavigate();
	const handleLogOut = async () => {
		try {
			await Auth.signOut();
			navigate("/");
			window.location.reload(false);
		} catch (exception) {
			console.error(exception);
		}
	};

	return (
		<Box width="100vw" className="center-column">
			<Button
				onClick={handleLogOut}
				sx={{
					padding: "8px",
					margin: "0px",
					borderRadius: "20px",
					position: "absolute",
					top: "20px",
					right: "20px",
					color: "black",
				}}
			>
				<ExitToAppIcon />
			</Button>
			<Typography
				marginTop="80px"
				fontSize="50px"
				fontWeight="700"
				fontFamily={"Georgia"}
				marginBottom={"10px"}
			>
				MoverMate
			</Typography>
			<Typography
				fontFamily={"Courier New"}
				fontSize="18px"
				fontWeight="500"
				marginBottom={"50px"}
				color="#268f06"
			>
				Welcome to be a new customer
			</Typography>
			<Typography
				fontFamily={"Courier New"}
				fontSize="28px"
				fontWeight="600"
				marginBottom={"70px"}
				color="#5a0480"
			>
				Next?
			</Typography>
			<Box>
				<Box className={styles.steps_container}>
					<Box className={styles.step_container}>
						<Box className={styles.step_title}>
							<Typography className={styles.step_title_text}>
								1
							</Typography>
						</Box>
						<Box className={styles.step_content_container}>
							<Typography
								className={styles.step_content}
								fontWeight="600"
							>
								Download the App from App Store or GooglePlay
							</Typography>
							<Box marginTop="30px">
								<IconButton
									sx={{ marginRight: "40px" }}
									onClick={() =>
										window.open(
											"https://apps.apple.com/ca/app/movermate/id6452838434?platform=iphone"
										)
									}
								>
									<AppleIcon />
								</IconButton>
								<IconButton
									sx={{ marginLeft: "40px" }}
									onClick={() =>
										window.open(
											"https://play.google.com/store/apps/details?id=com.movermate.movermate"
										)
									}
								>
									<GoogleIcon />
								</IconButton>
							</Box>
						</Box>
					</Box>
					<Box className={styles.step_container}>
						<Box className={styles.step_title}>
							<Typography className={styles.step_title_text}>
								2
							</Typography>
						</Box>
						<Box className={styles.step_content_container}>
							<Typography
								className={styles.step_content}
								fontWeight="600"
							>
								Send a Quote request in the App
							</Typography>
						</Box>
					</Box>
					<Box className={styles.step_container}>
						<Box className={styles.step_title}>
							<Typography className={styles.step_title_text}>
								3
							</Typography>
						</Box>
						<Box className={styles.step_content_container}>
							<Typography
								className={styles.step_content}
								fontWeight="600"
							>
								Companies will send you a quote, Happy moving!
							</Typography>
							<Typography
								fontWeight="500"
								fontSize="14px"
								marginTop="20px"
								fontFamily={"Courier New"}
							>
								{"Accept / Negotiate"}
							</Typography>
							<Typography
								fontWeight="600"
								fontSize="14px"
								marginTop="30px"
								fontFamily={"Georgia"}
							>
								{"Happy Moving!"}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
