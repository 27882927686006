import { useTable, useSortBy, usePagination } from "react-table";
import { Box, Typography, Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dateToString } from "../../util/Util";

export default function MediaProcessTable({
	columns,
	data,
	isCompleted = false,
}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		setPageIndex,
	} = useTable(
		{ columns, data, autoResetSortBy: false, autoResetPage: false },
		useSortBy,
		usePagination
	);

	const navigate = useNavigate();

	return (
		<Box className="center-column" width="100%">
			{data.length !== 0 && (
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									let title = column.render("Header");

									return (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
										>
											{title}
											<span>
												{column.isSorted
													? column.isSortedDesc
														? " ▾"
														: " ▴"
													: ""}
											</span>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);

							let classNameSelected = "";
							if (row.original.has_viewed === 0) {
								classNameSelected = "new_tr";
							}
							return (
								<tr
									{...row.getRowProps()}
									onClick={() => {
										if (isCompleted) {
											navigate(
												`/completed-tickets/ticket/${row.original.id}/${row.original.request_id}`
											);
										} else {
											navigate(
												`/media-tickets/ticket/${row.original.id}/${row.original.request_id}`
											);
										}
									}}
									className={classNameSelected}
								>
									{row.cells.map((cell) => {
										if (
											cell.column.id ===
												"creation_time" ||
											cell.column.id === "updated_time" ||
											cell.column.id === "completed_time"
										) {
											return (
												<td {...cell.getCellProps()}>
													{dateToString(
														new Date(cell.value)
													)}
												</td>
											);
										} else {
											return (
												<td {...cell.getCellProps()}>
													{cell.render("Cell")}
												</td>
											);
										}
									})}
									{row.original.has_viewed === 0 ? (
										<td>
											<Chip
												padding="2px"
												label="New"
												color="primary"
											/>
										</td>
									) : (
										<td></td>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
			<Box className="center-row" marginTop="20px">
				<Button
					onClick={() => previousPage()}
					style={{
						marginRight: "20px",
						backgroundColor: "#3b82f6",
						borderRadius: "5px",
						color: "#F4F4F4",
						width: "100px",
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
					}}
				>
					<Typography fontSize="15px"> Previous </Typography>
				</Button>
				<Button
					onClick={() => nextPage()}
					style={{
						marginRight: "20px",
						backgroundColor: "#3b82f6",
						borderRadius: "5px",
						color: "#F4F4F4",
						padding: "5px 10px",
						width: "100px",
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
					}}
				>
					<Typography fontSize="15px"> Next </Typography>
				</Button>
			</Box>
		</Box>
	);
}
