import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Auth } from "aws-amplify";
import { getAmplifyTokenAsync } from "./Amplify";

TimeAgo.addDefaultLocale(en);

export function dateToString(DateObj, date_type = "datetime") {
	// console.log("!!!", DateObj);
	// DateObj.setHours(DateObj.getHours() + 4);

	// Match the time stored inside database
	let gmtRe = /GMT([\-\+]?\d{4})/;
	let tz = gmtRe.exec(DateObj)[1]; // +1000
	let offset_hour = tz / 100; // 10
	let offset_min = tz % 100; // 0
	DateObj.setHours(DateObj.getHours() - offset_hour);
	DateObj.setMinutes(DateObj.getMinutes() - offset_min);

	let month =
		(DateObj.getMonth() + 1).toString().length === 1
			? "0" + (DateObj.getMonth() + 1)
			: DateObj.getMonth() + 1;

	let date =
		DateObj.getDate().toString().length === 1
			? "0" + DateObj.getDate()
			: DateObj.getDate();
	let hour =
		DateObj.getHours().toString().length === 1
			? "0" + DateObj.getHours()
			: DateObj.getHours();
	let min =
		DateObj.getMinutes().toString().length === 1
			? "0" + DateObj.getMinutes()
			: DateObj.getMinutes();
	if (date_type === "date") return DateObj.getFullYear() + "-" + month + "-" + date;
	return (
		DateObj.getFullYear() +
		"-" +
		month +
		"-" +
		date +
		" " +
		hour +
		":" +
		min
	);
}

export function getTimeOffset() {
	// Create a new Date object for the current time
	const now = new Date();

	// Get the time zone offset in minutes
	const offsetInMinutes = now.getTimezoneOffset();

	// Convert the offset to hours (note: getTimezoneOffset() returns the difference
	// in minutes between UTC and the local time, where local time ahead of UTC is negative)
	const offsetInHours = -offsetInMinutes / 60;

	return offsetInHours;
}

export function dateTimeAgo(date) {
	let timeOffset = getTimeOffset();
	date.setHours(date.getHours() - timeOffset);
	let timeAgo = new TimeAgo("en-US");
	// date.setHours(date.getHours() + 5);
	return timeAgo.format(date);
}

export async function getUserId() {
	Auth.currentAuthenticatedUser()
		.then((user) => {
			return user.id;
		})
		.catch((err) => console.log(err));
}

export async function getUserData() {
	let user = await Auth.currentAuthenticatedUser();
	let jwt = await getAmplifyTokenAsync();
	let userData = await fetch(
		`${process.env.REACT_APP_AI_MOVING_API}/user/${user.attributes.email}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		}
	);

	if (!userData.ok || userData.status !== 200)
		throw new Error(userData.statusText);

	userData = await userData.json();
	return userData;
}

export async function getAccountId() {
	let user = await Auth.currentAuthenticatedUser();
	let jwt = await getAmplifyTokenAsync();
	let userData = await fetch(
		`${process.env.REACT_APP_AI_MOVING_API}/user/${user.attributes.email}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: jwt,
			},
		}
	);

	if (!userData.ok || userData.status !== 200)
		throw new Error(userData.statusText);

	userData = await userData.json();

	return userData.account_id;
}
