import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Menu, MenuItem } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate, NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import "./Header.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { getAccountId } from "../../util/Util";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import styles from "./Header.module.scss";

export default function Header(props) {
	const navigate = useNavigate();
	const theme = useTheme();
	const [account, setAccount] = useState({});

	const [settingAnchor, setSettingAnchor] = useState(null);
	const getAccountInfo = async () => {
		let jwt = await getAmplifyTokenAsync();
		let accountId = await getAccountId();
		let accountInfo = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/account/${accountId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);

		if (!accountInfo.ok || accountInfo.status !== 200)
			throw new Error(accountInfo.statusText);
		accountInfo = await accountInfo.json();
		setAccount(accountInfo);
		console.log("Account Info: ", accountInfo);
	};

	const handleLogOut = async () => {
		try {
			await Auth.signOut();
			navigate("/");
			window.location.reload(false);
		} catch (exception) {
			console.error(exception);
		}
	};
	useEffect(() => {
		(async () => {
			await getAccountInfo();
		})();
	}, []);

	return (
		<Box
			className="center-row header-wrapper"
			justifyContent={"space-between"}
			position="sticky"
		>
			<Box className="center-row">
				<img
					src={require("../../assets/moving-loading.gif")}
					alt="AI Moving Logo"
					style={{ height: "50px" }}
				/>
				{/* <Typography fontWeight={"700"}>AI Moving</Typography> */}
				<Typography fontWeight={"600"}>{account.name}</Typography>
			</Box>

			<nav id={styles.sidebar}>
				<NavLink
					className={({ isActive, isPending }) =>
						isActive ? styles.nav_link_active : styles.nav_link
					}
					to="/"
				>
					Request For Estimate
				</NavLink>
				<NavLink
					className={({ isActive, isPending }) =>
						isActive ? styles.nav_link_active : styles.nav_link
					}
					to="/case-dashboard"
				>
					Cases
				</NavLink>
				<Button
					className={styles.nav_link}
					sx={{
						height: "41px",
						margin: "0px",
					}}
					onClick={(event) => {
						setSettingAnchor(event.currentTarget);
					}}
				>
					Settings
				</Button>
				<Menu
					anchorEl={settingAnchor}
					open={Boolean(settingAnchor)}
					onClose={() => setSettingAnchor(null)}
				>
					<MenuItem>
						<NavLink
							className={({ isActive, isPending }) =>
								isActive
									? styles.nav_link_active
									: styles.nav_link
							}
							to="/iventory-item"
						>
							Inventory Items Management
						</NavLink>
						{/* Inventory Item Mang */}
					</MenuItem>
					<MenuItem>
						<NavLink
							className={({ isActive, isPending }) =>
								isActive
									? styles.nav_link_active
									: styles.nav_link
							}
							to="/estimate-template"
						>
							Estimate Template
						</NavLink>
					</MenuItem>
					<MenuItem>
						<NavLink
							className={({ isActive, isPending }) =>
								isActive
									? styles.nav_link_active
									: styles.nav_link
							}
							to="/user-management"
						>
							User Management
						</NavLink>
					</MenuItem>
				</Menu>
				{/* </Box> */}
				<Button
					onClick={handleLogOut}
					color="error"
					sx={{
						padding: "8px",
						margin: "0px",
						borderRadius: "20px",
					}}
				>
					<ExitToAppIcon />
				</Button>
			</nav>
		</Box>
	);
}
