import {
	Dialog,
	Box,
	Typography,
	TextField,
	IconButton,
	MenuItem,
	Button,
} from "@mui/material";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import styles from "./Settings.module.scss";
import { useState } from "react";

export default function UserManagementCreateNew({
	userTypeList,
	handleCreateNewUser,
	hideModal,
}) {
	const [newUser, setNewUser] = useState({
		id: -1,
		type: "3",
		name: "",
		email: "",
		phone: "",
	});
	const userColumns = [
		{
			name: "Name",
			fieldName: "name",
			required: true,
		},
		{
			name: "Email",
			fieldName: "email",
			required: true,
		},
		{
			name: "Phone",
			fieldName: "phone",
			required: false,
		},
		{
			name: "Type",
			fieldName: "type",
			required: true,
		},
	];

	return (
		<Box className={styles.create_new_container}>
			<IconButton
				sx={{
					position: "absolute",
					top: "-8px",
					right: "-8px",
				}}
				color="remove"
				onClick={() => hideModal()}
			>
				<CancelTwoToneIcon sx={{ fontSize: 25 }} />
			</IconButton>
			<Box className="center-column">
				{userColumns.map((col, index) => {
					if (col.name === "Type") {
						return (
							<Box
								className={styles.create_new_item}
								key={col.fieldName}
							>
								<TextField
									label="Type"
									select
									value={newUser[col.fieldName]}
									onChange={(e) => {
										setNewUser({
											...newUser,
											[col.fieldName]: e.target.value,
										});
									}}
									sx={{
										minWidth: "220px",
										"& .MuiInputBase-input": {
											padding: "8px !important",
										},
									}}
								>
									{userTypeList.map((type) => (
										<MenuItem
											key={type.value}
											value={type.value}
										>
											{type.label}
										</MenuItem>
									))}
								</TextField>
							</Box>
						);
					} else {
						return (
							<Box
								className={styles.create_new_item}
								key={col.fieldName}
							>
								{/* <Typography>
									{col.name} {col.required && " *"}
								</Typography> */}
								<TextField
									label={col.name}
									required={col.required}
									value={newUser[col.fieldName]}
									onChange={(e) => {
										setNewUser({
											...newUser,
											[col.fieldName]: e.target.value,
										});
									}}
									sx={{
										minWidth: "220px",
										label: {
											color: "#8a8883",
										},
										"& .MuiInputBase-input": {
											padding: "8px !important",
										},
									}}
								/>
							</Box>
						);
					}
				})}
				<Box className="center-row" sx={{ marginTop: "15px" }}>
					<Button
						sx={{ padding: "3px 10px" }}
						variant="contained"
						onClick={() => handleCreateNewUser(newUser)}
					>
						Save{" "}
						<SaveTwoToneIcon
							sx={{ fontSize: "20px", marginLeft: "5px" }}
						/>
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
