import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import Title from "../Title";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import Loading from "../Loading";
import RequestTable from "./RequestTable";
import { getAccountId } from "../../util/Util";
import { useNavigate } from "react-router-dom";
import {
	DataGrid,
	GridToolbar,
	gridClasses,
	gridPageCountSelector,
	GridPagination,
	useGridApiContext,
	useGridSelector,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import MuiPagination from "@mui/material/Pagination";
import { StyledDataGrid, DefaultDataGrid } from "../DataGrid/StyledDataGrid";
import { dateToString } from "../../util/Util";
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	minHeight: "400px",
	boxShadow: 8,
	borderRadius: 4,
	backgroundColor: "rgba(255,255,255,0.9)",
	"& .MuiDataGrid-row:hover": {
		color: "primary.main",
		backgroundColor: "primary.main",
	},
	"& .super-app-theme--header": {
		backgroundColor: "#f7fafb",
		color: "#222121",
		fontWeight: "800",
	},
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: "rgba(255,255,255)",
		"&:hover, &.Mui-hovered": {
			backgroundColor: "rgba(255,255,255)",
			"@media (hover: none)": {
				backgroundColor: "rgba(255,255,255)",
			},
		},
		"&.Mui-selected": {
			backgroundColor: "rgba(255,255,255)",
			"&:hover, &.Mui-hovered": {
				backgroundColor: "rgba(255,255,255)",
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: "rgba(255,255,255)",
				},
			},
		},
	},
}));

function Pagination({ page, onPageChange, className }) {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

function CustomPagination(props) {
	console.log("Inside Pagniation2!!!!!!!!");
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
	"& .ant-empty-img-1": {
		fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
	},
	"& .ant-empty-img-2": {
		fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
	},
	"& .ant-empty-img-3": {
		fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
	},
	"& .ant-empty-img-4": {
		fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
	},
	"& .ant-empty-img-5": {
		fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
		fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
	},
}));

export default function RequestList() {
	const [isLoading, setIsLoading] = useState(true);
	const [requestList, setRequestList] = useState([]);
	const navigate = useNavigate();
	// const requestTableColumn = useMemo(() => [
	// 	{
	// 		Header: "Id",
	// 		accessor: "request_id",
	// 	},
	// 	{
	// 		Header: "Name",
	// 		accessor: "user_name",
	// 	},
	// 	{
	// 		Header: "From",
	// 		accessor: "request.moving_from",
	// 	},
	// 	{
	// 		Header: "To",
	// 		accessor: "request.moving_to",
	// 	},
	// 	{
	// 		Header: "Moving Date",
	// 		accessor: "request.moving_date",
	// 	},
	// 	{
	// 		Header: "Created Time",
	// 		accessor: "request.creation_time",
	// 	},
	// 	{
	// 		Header: "Status",
	// 		accessor: "has_viewed",
	// 	},
	// ]);

	const requestDataGridColumns = [
		{
			field: "request_id",
			headerName: "Id",
			minWidth: 50,
			editable: false,
		},
		{
			field: "user_name",
			headerName: "Name",
			minWidth: 50,
			flex: 1,
			editable: false,
		},
		{
			field: "request.moving_from",
			headerName: "From",
			minWidth: 200,
			flex: 2,
			editable: false,
			valueGetter: (params) => {
				return params.row.request.moving_from;
			},
		},
		{
			field: "request.moving_to",
			headerName: "To",
			minWidth: 200,
			flex: 2,
			editable: false,
			valueGetter: (params) => {
				return params.row.request.moving_to;
			},
		},
		{
			field: "request.moving_date",
			headerName: "Moving Date",
			minWidth: 100,
			flex: 1,
			editable: false,
			valueGetter: (params) => {
				return dateToString(new Date(params.row.request.moving_date), 'date');
			},
		},
		{
			field: "request.creation_time",
			headerName: "Created Time",
			minWidth: 100,
			flex: 1,
			editable: false,
			valueGetter: (params) => {
				return dateToString(new Date(params.row.request.creation_time));
			},
		},
		{
			field: "has_viewed",
			headerName: "Status",
			minWidth: 100,
			flex: 1,
			editable: false,
			valueGetter: (params) => {
				let value = params.row.has_viewed;
				if (value === 0) {
					return "New";
				}
				if (value === 1) {
					return "Viewed";
				}
				if (value === 2) {
					return "In Progress";
				}
				if (value === 3) {
					return "Not Avaiable";
				}
			},
			renderCell: (params) => {
				let value = params.row.has_viewed;
				let bg = "";
				let text = "";
				if (value === 0) {
					bg = "#5a81ed";
					text = "New";
				} else if (value === 1) {
					bg = "#e07c0b";
					text = "Viewed";
				} else if (value === 2) {
					bg = "#57b041";
					text = "In Progress";
				} else if (value === 3) {
					bg = "#a6a8a5";
					text = "Not Avaiable";
				}
				return (
					<Box
						className="center-row"
						style={{
							backgroundColor: bg,
							borderRadius: "15px",
							color: "#F4F4F4",
							padding: "6px 10px",
						}}
					>
						<Typography fontSize="12px" fontWeight="600">
							{text}
						</Typography>
					</Box>
				);
			},
		},
	];

	const getRequestList = async () => {
		let jwt = await getAmplifyTokenAsync();
		let account_id = await getAccountId();
		console.log("account_id", account_id);
		let request_list = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/requests/account/${account_id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);

		if (!request_list.ok || request_list.status !== 200)
			throw new Error(request_list.statusText);
		request_list = await request_list.json();
		console.log("request_list", request_list);
		setRequestList(request_list);
	};

	useEffect(() => {
		(async () => {
			await getRequestList();
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		const interval = setInterval(async () => {
			await getRequestList();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box className="content-container-wrap">
			<Box className="content-container">
				<Box height="8%" className="center-row">
					<Title title="Request For Estimate" />
				</Box>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="start"
					flexWrap={"wrap"}
					height="92%"
					width="100%"
					justifyContent="space-evenly"
					overflow={"scroll"}
					className="center-column"
				>
					<Box
						className="center-column"
						width="96%"
						style={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
						marginTop="15px"
						marginBottom="10px"
					>
						{/* <RequestTable
							columns={requestTableColumn}
							data={requestList}
						/> */}
						<DefaultDataGrid
							rows={requestList}
							columns={requestDataGridColumns}
							onRowClick={(params, event) => {
								navigate(
									`/request-for-estimate/request-detail/${params.row.request_id}`
								);
							}}
							hideFooterSelectedRowCount
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage % 2 === 0
									? "even"
									: "odd"
							}
						/>
					</Box>
				</Box>
			</Box>

			<Outlet />
		</Box>
	);
}
