import React from "react";
import { useEffect, useRef, useState, useLocation } from "react";
import { useParams, useNavigate, params } from "react-router-dom";
import {
	Dialog,
	DialogTitle,
	Modal,
	Box,
	Button,
	IconButton,
	Badge,
} from "@mui/material";
import MediaProcessMediaDetail from "../../components/MediaProcess/MediaProcessMediaDetail";
import InventoryList from "../../components/Inventory/InventoryList";
import { getAmplifyTokenAsync } from "../../util/Amplify";
export default function MediaprocessTicketDetailModal() {
	const navigate = useNavigate();
	let { ticketId, requestId } = useParams();
	const inventoryRef = useRef();
	const [inventoryListId, setInventoryListId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			updateHasViewed();
			// let result = await hasPermissionViewRequest();
			let result = false;
			if (!result) {
				if (window.location.href.includes("completed-tickets")) {
					let hasCompleted = await hasCompletedRequest();
					if (hasCompleted.id !== -1) {
						setInventoryListId(hasCompleted.id);
					}
				}
			} else {
				navigate(-1);
			}
			setIsLoading(false);
		})();
	}, []);

	const updateHasViewed = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/media_ticket_has_viewed/${ticketId}`,
			{
				method: "PUT",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
	};

	const hasPermissionViewRequest = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request/status_id/${requestId}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		return res.status_id === 2;
	};

	const hasCompletedRequest = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/ai_inventory_list_id/${requestId}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);

		res = await res.json();
		return res;
	};

	const submitInventory = async () => {
		let jwt = await getAmplifyTokenAsync();

		let isValid = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/media_ticket/check_valid/${ticketId}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!isValid.ok || isValid.status !== 200)
			throw new Error(isValid.statusText);
		isValid = await isValid.json();
		if (isValid.valid) {
			let inventoryList =
				inventoryRef.current.getInventoryListWithItems();
			let res = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/create_inventory_list`,
				{
					method: "POST",
					headers: {
						Authorization: jwt,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						request_id: requestId,
						inventory_list: inventoryList,
					}),
				}
			);
			if (!res.ok || res.status !== 200) throw new Error(res.statusText);

			let updateMediaTicket = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/media_ticket/${ticketId}`,
				{
					method: "PUT",
					headers: {
						Authorization: jwt,
						"Content-Type": "application/json",
					},
				}
			);
			if (!updateMediaTicket.ok || updateMediaTicket.status !== 200)
				throw new Error(updateMediaTicket.statusText);
			
			let updateRequestRes = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/request/completed_ticket/${requestId}`,
				{
					method: "PUT",
					headers: {
						Authorization: jwt,
						"Content-Type": "application/json",
					},
				}
			);
			if (!updateRequestRes.ok || updateRequestRes.status !== 200)
				throw new Error(updateRequestRes.statusText);
			navigate("/");
		} else {
			navigate("/");
		}
	};

	if (isLoading) return <div></div>;

	return (
		<Dialog
			open={true}
			onClose={() => navigate(-1)}
			// scroll="paper"
			// aria-labelledby="scroll-dialog-title"
			// aria-describedby="scroll-dialog-description"
			sx={{
				"& .MuiDialog-paper": {
					position: "absolute",
					marginLeft: "3%",
					marginRight: "3%",
					backgroundColor: "rgba(255,255,255,0.6)",
					borderRadius: "20px",
					height: "96vh",
					flexDirection: "row",
					display: "flex",
					justifyContent: "space-evenly",
					alignItems: "center",
				},
			}}
			fullWidth={true}
			maxWidth={"xl"}
		>
			<Box
				sx={{
					width: "48%",
					height: "95%",
					backgroundColor: "rgba(255,255,255,0.8)",
					borderRadius: "20px",
				}}
			>
				<MediaProcessMediaDetail requestId={requestId} />
			</Box>
			<Box
				sx={{
					width: "48%",
					height: "95%",
					backgroundColor: "rgba(255,255,255,0.8)",
					backdropFilter: "blur(2px)",
					borderRadius: "20px",
				}}
			>
				{inventoryListId == null ? (
					<InventoryList
						isEditabled={true}
						isFold={false}
						setIsFold={() => {}}
						ref={inventoryRef}
					/>
				) : (
					<InventoryList
						isEditabled={false}
						isFold={false}
						setIsFold={() => {}}
						ref={inventoryRef}
						inventoryListId={inventoryListId}
					/>
				)}
			</Box>

			<Button
				onClick={() => submitInventory()}
				style={{
					position: "absolute",
					bottom: "5%",
					left: "50% - 90px",
					color: "white",
					width: "180px",
					padding: "12px 0px",
					borderRadius: "25px",
					backgroundColor: "#3b82f6",
					boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.3)",
					fontSize: "16px",
					fontWeight: "600",
				}}
			>
				Submit
			</Button>
		</Dialog>
	);
}
