import { Box, AppBar, Typography, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../components/Template/Header";

export default function Root() {
	return (
		<Box
			height="100vh"
			w="100vw"
			sx={{
				backgroundImage: `url(${require("../assets/bg.png")})`,
				backgroundSize: "cover",
			}}
			overflow="scroll"
		>
			<Header />

			<Box
				style={{
					height: "85vh",
					zIndex: 1,
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
}
