import {
	Box,
	Typography,
	TextField,
	InputAdornment,
	IconButton,
	Button,
} from "@mui/material";
import { useState } from "react";
import ControlPointTwoToneIcon from "@mui/icons-material/ControlPointTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import UnfoldLessTwoToneIcon from "@mui/icons-material/UnfoldLessTwoTone";
import UnfoldMoreTwoToneIcon from "@mui/icons-material/UnfoldMoreTwoTone";
import styles from "./EstimateDetailSection.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "../Util/EditorToolbar";
import EstimateDetailProtection from "./EstimateDetailProtection";
export default function EstimateDetailSection({
	section,
	isEditabled,
	sectionIndex,
	updateEntry,
	isFold = false,
	addNewEntry,
	deleteEntry,
	deleteSection,
	isSettingMode = false,
}) {
	let isRateSection = section.section_name === "Relocation Rate";
	const [foldState, setFoldState] = useState(isFold);
	const constSectionName = [
		"Relocation Rate",
		"Moving Company Info",
		"Moving From",
		"Moving To",
		"Relocation Details",
	];

	let isNormalSection = true;
	if (section.section_name === "Relocation Rate") {
		isNormalSection = false;
	}
	if (section.section_name === "Understanding Your Estimate") {
		isNormalSection = false;
	}
	if (section.section_name === "Full Value of Protection") {
		isNormalSection = false;
	}
	return (
		<Box className={styles.section_container}>
			<Box
				className={
					isEditabled
						? styles.section_title
						: styles.section_title_readonly
				}
			>
				<Typography fontWeight={"600"} color="white">
					{section.section_name}
				</Typography>

				<Box>
					{isEditabled && (
						<IconButton
							display={isEditabled ? "auto" : "none"}
							// variant="contained"
							className={styles.icon_button}
							sx={{ backgroundColor: "#fff" }}
							onClick={() => addNewEntry(sectionIndex)}
						>
							<ControlPointTwoToneIcon
								color="confirm"
								sx={{ fontSize: 20 }}
							/>
						</IconButton>
					)}
					{isEditabled &&
						!constSectionName.includes(section.section_name) && (
							<IconButton
								color="remove"
								className={styles.icon_button}
								sx={{
									marginLeft: "10px",
									backgroundColor: "#fff",
								}}
								onClick={() => {
									deleteSection(sectionIndex);
								}}
							>
								<RemoveCircleTwoToneIcon
									sx={{ fontSize: 20 }}
								/>
							</IconButton>
						)}
					<IconButton
						color="primary"
						className={styles.icon_button}
						sx={{ marginLeft: "10px", backgroundColor: "#fff" }}
						onClick={() => setFoldState(!foldState)}
					>
						{foldState ? (
							<UnfoldMoreTwoToneIcon sx={{ fontSize: 20 }} />
						) : (
							<UnfoldLessTwoToneIcon sx={{ fontSize: 20 }} />
						)}
					</IconButton>
				</Box>
			</Box>
			{/* Render Normal Section*/}
			{!foldState &&
				isNormalSection &&
				section.entry_list.map((entry, entryIndex) => {
					return (
						<Box
							key={entryIndex}
							className={styles.entry_list_container}
						>
							{isEditabled ? (
								<TextField
									width={isSettingMode ? "80%" : "40%"}
									InputProps={{
										className: styles.text_input,
									}}
									value={entry.entry_name}
									onChange={(e) => {
										updateEntry(
											sectionIndex,
											entryIndex,
											e.target.value,
											"entry_name"
										);
									}}
								/>
							) : (
								<Typography
									width="40%"
									color="#5c5a5a"
									fontSize="14px"
								>
									{entry.entry_name}
								</Typography>
							)}
							<Typography
								sx={{ marginLeft: "6px", marginRight: "6px" }}
							>
								:
							</Typography>
							<Box width="55%">
								{isEditabled && (
									<TextField
										fullWidth
										InputProps={{
											className: styles.text_input,
										}}
										value={
											isSettingMode
												? entry.default_value
												: entry.value
										}
										onChange={(e) => {
											updateEntry(
												sectionIndex,
												entryIndex,
												e.target.value,
												"value"
											);
										}}
									/>
								)}

								{!isEditabled && !isSettingMode && (
									<Typography
										width="100%"
										fontSize="16px"
										style={{ wordWrap: "break-word" }}
									>
										{isRateSection && "$"}
										{entry.value}
									</Typography>
								)}
							</Box>
							{isEditabled && (
								<IconButton
									color="remove"
									display={isEditabled ? "auto" : "none"}
									sx={{ marginLeft: "5px" }}
									onClick={() =>
										deleteEntry(sectionIndex, entryIndex)
									}
								>
									<RemoveCircleTwoToneIcon
										sx={{ fontSize: 20 }}
									/>
								</IconButton>
							)}
						</Box>
					);
				})}
			{/* Render Relocation Rate */}
			{!foldState &&
				section.section_name === "Relocation Rate" &&
				section.entry_list.map((entry, entryIndex) => {
					return (
						<Box
							key={entryIndex}
							className={styles.entry_list_container}
						>
							{isEditabled && (
								<TextField
									disabled={
										entry.entry_name ===
										"Total Moving Estimate"
									}
									width={isSettingMode ? "80%" : "40%"}
									InputProps={{
										className: styles.text_input,
									}}
									value={entry.entry_name}
									onChange={(e) => {
										updateEntry(
											sectionIndex,
											entryIndex,
											e.target.value,
											"entry_name"
										);
									}}
								/>
							)}

							{!isEditabled && (
								<Typography
									width="40%"
									color="#5c5a5a"
									fontSize="14px"
								>
									{entry.entry_name}
								</Typography>
							)}
							<Typography
								sx={{ marginLeft: "6px", marginRight: "6px" }}
							>
								:
							</Typography>
							<Box width="55%">
								{isEditabled && (
									<TextField
										fullWidth
										type="number"
										InputProps={{
											startAdornment: isRateSection && (
												<InputAdornment position="start">
													<Typography
														fontSize="16px"
														paddingLeft="10px"
														backgroundColor="#dce0dc"
														paddingRight="10px"
														color="#057a25"
														marginLeft={"5px"}
													>
														$
													</Typography>
												</InputAdornment>
											),
											className: styles.text_input,
										}}
										value={
											isSettingMode
												? entry.default_value
												: entry.value
										}
										onChange={(e) => {
											updateEntry(
												sectionIndex,
												entryIndex,
												e.target.value,
												"value"
											);
										}}
									/>
								)}

								{!isEditabled && !isSettingMode && (
									<Typography
										width="100%"
										fontSize="16px"
										// color="#059c20"
										style={{ wordWrap: "break-word" }}
									>
										{isRateSection && "$"}
										{entry.value}
									</Typography>
								)}
							</Box>
							{isEditabled && (
								<IconButton
									color="remove"
									disabled={
										entry.entry_name ===
										"Total Moving Estimate"
									}
									display={isEditabled ? "auto" : "none"}
									sx={{ marginLeft: "5px" }}
									onClick={() =>
										deleteEntry(sectionIndex, entryIndex)
									}
								>
									<RemoveCircleTwoToneIcon
										sx={{ fontSize: 20 }}
									/>
								</IconButton>
							)}
						</Box>
					);
				})}
			{/* Render Understanding Your Estimate Section*/}
			{!foldState &&
				section.section_name === "Understanding Your Estimate" && (
					<Box
						className={styles.entry_list_container}
						justifyContent={"center"}
					>
						{isEditabled && (
							<Box width="95%" pb="20px">
								{/* <EditorToolbar /> */}
								<ReactQuill
									theme="snow"
									style={{
										width: "100%",
									}}
									value={
										isSettingMode
											? section.entry_list[0]
													.default_value
											: section.entry_list[0].value
									}
									onChange={(e) => {
										updateEntry(
											sectionIndex,
											0,
											e,
											"value"
										);
									}}
									// modules={modules}
									// formats={formats}
								/>
							</Box>
						)}
						{!isEditabled && section.entry_list[0].value !== "" && (
							<Box width="95%" pb="20px">
								<ReactQuill
									theme="snow"
									style={{
										width: "100%",
									}}
									value={section.entry_list[0].value}
									readOnly={true}
									modules={{ toolbar: false }}
								/>
							</Box>
						)}
					</Box>
				)}

			{/* Render Full Value of Protection Section*/}
			{!foldState &&
				section.section_name === "Full Value of Protection" && (
					<EstimateDetailProtection
						items={section.entry_list}
						isEditabled={isEditabled}
						isSettingMode={isSettingMode}
						updateEntry={updateEntry}
						deleteEntry={deleteEntry}
						sectionIndex={sectionIndex}
					/>
				)}
		</Box>
	);
}
