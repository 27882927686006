import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Box, Typography } from "@mui/material";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import ModalCloseBtn from "../../components/ModalCloseBtn";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import { DefaultDataGrid } from "../../components/DataGrid/StyledDataGrid";
import Title from "../../components/Title";
import SendIcon from "@mui/icons-material/Send";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DoneGif from "../../components/DoneGif";
export default function CaseDetailAssign({
	hasOpenModal,
	setHasOpenModal,
	caseId,
	requestId,
}) {
	const [moverList, setMoverList] = useState([]);
	const [openDoneGif, setOpenDoneGif] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const userDataGridColumn = [
		// {
		// 	field: "id",
		// 	headerName: "ID",
		// 	minWidth: 50,
		// 	flex: 1,

		// 	headerAlign: "center",
		// 	align: "center",
		// 	editable: false,
		// 	cellClassName: "uneditable-cell",
		// },
		{
			field: "name",
			headerName: "Name",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 100,
			flex: 3,
			headerAlign: "center",
			align: "center",
			editable: false,
			cellClassName: "uneditable-cell",
		},
		{
			field: "phone",
			headerName: "Phone",
			minWidth: 100,
			flex: 2,
			headerAlign: "center",
			align: "center",
			editable: false,
		},

		{
			field: "active",
			headerName: "Active",
			minWidth: 50,
			flex: 1,
			headerAlign: "center",
			align: "center",
			type: "boolean",
			editable: false,
		},
		{
			field: "is_assign",
			flex: 2,
			headerName: "Status",
			minWidth: 100,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				let bg = "#fcdee0";
				let color = "#c74e56";

				if (params.value) {
					bg = "#d5f5dd";
					color = "#1e8449";
					return (
						<Box
							backgroundColor={bg}
							padding="5px"
							borderRadius="8px"
							className="center-row"
						>
							<Typography
								sx={{
									color: { color },
									fontWeight: "500",
									fontSize: "12px",
									marginRight: "5px",
								}}
							>
								{params.value ? "Assigned" : "Not Assigned"}
							</Typography>
							<AddTaskIcon
								color="success"
								sx={{ fontSize: "18px" }}
							/>
						</Box>
					);
				} else {
					return <></>;
				}
			},
		},
		{
			field: "assign_action",
			headerName: "",
			editable: false,
			align: "center",
			flex: 1,
			renderCell: (params) => {
				if (params.row.is_assign) {
					return (
						<IconButton
							sx={{
								padding: "5px",
								backgroundColor: "rgba(230,242,250,0.8)",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							}}
							color="remove"
							onClick={() => {
								cancelAssignTicket(params.row.ticket.id);
							}}
						>
							<DoNotDisturbOnIcon sx={{ fontSize: "18px" }} />
						</IconButton>
					);
				} else {
					return (
						<IconButton
							sx={{
								padding: "5px",
								backgroundColor: "rgba(230,242,250,0.8)",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							}}
							color="primary"
							onClick={() => {
								assignTicket(params.row.id);
							}}
						>
							<SendIcon sx={{ fontSize: "18px" }} />
						</IconButton>
					);
				}
			},
		},
	];

	useEffect(() => {
		if (hasOpenModal) {
			(async () => {
				await getMoverList();
				setIsLoading(false);
			})();
		}
	}, [hasOpenModal]);

	const assignTicket = async (mover_id) => {
		if (window.confirm("Are you sure you want to assign to this mover?")) {
			let jwt = await getAmplifyTokenAsync();
			let assign_ticket = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/mover_ticket/assign`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
					body: JSON.stringify({
						mover_id: mover_id,
						case_id: caseId,
						request_id: requestId,
					}),
				}
			);
			if (!assign_ticket.ok || assign_ticket.status !== 200)
				throw new Error(assign_ticket.statusText);

			setOpenDoneGif(true);
			setTimeout(() => {
				setOpenDoneGif(false);
			}, 2000);
			await getMoverList();
		}
	};

	const cancelAssignTicket = async (ticket_id) => {
		if (
			window.confirm(
				"Are you sure you want to cancel the job from this mover?"
			)
		) {
			let jwt = await getAmplifyTokenAsync();
			let assign_ticket = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/mover_ticket/unassign`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
					body: JSON.stringify({
						ticket_id: ticket_id,
					}),
				}
			);
			if (!assign_ticket.ok || assign_ticket.status !== 200)
				throw new Error(assign_ticket.statusText);

			setOpenDoneGif(true);
			setTimeout(() => {
				setOpenDoneGif(false);
			}, 2000);
			await getMoverList();
		}
	};

	const getMoverList = async () => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let mover_list = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/user/movers/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!mover_list.ok || mover_list.status !== 200)
			throw new Error(mover_list.statusText);
		mover_list = await mover_list.json();
		console.log("Set Movers", mover_list["movers"]);
		setMoverList(mover_list["movers"]);
	};

	if (hasOpenModal) {
		return (
			<Dialog
				open={hasOpenModal}
				sx={{
					"& .MuiDialog-paper": {
						position: "absolute",
						marginLeft: "0%",
						width: "800px",
						backgroundColor: "rgba(255,255,255,0.6)",
						borderRadius: "20px",
						height: "60vh",
						minHeight: "600px",
						flexDirection: "row",
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					},
				}}
				maxWidth={"xl"}
			>
				<DoneGif isOpen={openDoneGif} />
				<ModalCloseBtn onClick={() => setHasOpenModal(false)} />
				<Box
					className="center-column"
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(255,255,255,0.6)",
					}}
				>
					<Box className="center-column" width="100%" height="60px">
						<Title title="Assign Mover" />
					</Box>
					<Box
						sx={{
							width: "94%",
							height: "calc(100% - 90px)",
						}}
					>
						<DefaultDataGrid
							rows={moverList}
							columns={userDataGridColumn}
							isLoading={isLoading}
							hideFooterSelectedRowCount
							getRowClassName={(params) =>
								params.indexRelativeToCurrentPage % 2 === 0
									? "even"
									: "odd"
							}
						/>
					</Box>
				</Box>
			</Dialog>
		);
	} else {
		return (
			<IconButton
				sx={{
					position: "absolute",
					top: "4%",
					left: "4%",
					backgroundColor: "rgba(255,255,255,0.8)",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					borderRadius: "60px",
				}}
				color="success"
				onClick={() => {
					setHasOpenModal(true);
				}}
			>
				<AssignmentIndTwoToneIcon sx={{ fontSize: "28px" }} />
			</IconButton>
		);
	}
}
