import {
	DataGrid,
	GridToolbar,
	gridClasses,
	gridPageCountSelector,
	GridPagination,
	useGridApiContext,
	useGridSelector,
	GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import MuiPagination from "@mui/material/Pagination";
import { Typography, Box } from "@mui/material";

function Pagination({ page, onPageChange, className }) {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);
	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

function CustomPagination(props) {
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const handleRowEditStop = (params, event) => {
	console.log(params.reason);
	if (params.reason === GridRowEditStopReasons.rowFocusOut) {
		event.defaultMuiPrevented = true;
	}
};

export function DefaultDataGrid(props) {
	return (
		<StyledDataGrid
			slots={{
				toolbar: GridToolbar,
				pagination: CustomPagination,
			}}
			slotProps={{
				toolbar: {
					showQuickFilter: true,
				},
			}}
			initialState={{
				pagination: {
					paginationModel: { pageSize: 10 },
				},
			}}
			pageSizeOptions={[10, 20, 50]}
			// handleRowEditStop={handleRowEditStop}
			{...props}
		/>
	);
}

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	width: "100%",
	boxShadow:
		"rgba(0, 0, 0, 0.4) 0px 2px 3px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px",
	borderRadius: 10,
	backgroundColor: "rgba(255,255,255,0.85)",
	"& .uneditable-cell & .MuiDataGrid-row--editing": {
		backgroundColor: "rgba(1,255,255,0.85)",
	},
	"& .MuiDataGrid-cellContent": {
		color: "#525151 !important",
	},
	"& .MuiButton-root": {
		margin: "2px",
		border: "1px solid #3b82f6",
		boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
		// backgroundColor: "white",
		paddingLeft: "5px",
		paddingRight: "5px",
		borderRadius: "10px",
		marginLeft: "5px",
		color: theme.palette.primary.main,
	},
	'& .MuiDataGrid-booleanCell[data-value="true"]': {
		color: "green",
	},
	"& .MuiDataGrid-columnHeader": {
		borderBottom: "1px solid #8d8e8f",
	},
	"& .MuiDataGrid-footerContainer": {
		borderTop: "1px solid #8d8e8f",
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "800",
	},
	"& .MuiDataGrid-row:hover": {
		color: "primary.main",
		backgroundColor: "primary.main",
	},
	"& .MuiTablePagination-select": {
		paddingRight: "24px !important",
	},
	"& .MuiDataGrid-cell": {
		whiteSpace: "normal !important",
		// wordWrap: "break-word !important",
	},
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		"&:hover, &.Mui-hovered": {
			cursor: "pointer",
			backgroundColor: "rgba(220,220,220,0.6)",
		},
		// "&.Mui-selected": {
		// 	backgroundColor: "rgba(255, 255, 255, 0.9)",
		// 	"&:hover, &.Mui-hovered": {
		// 		backgroundColor: "rgba(255,255,255)",
		// 		// Reset on touch devices, it doesn't add specificity
		// 		"@media (hover: none)": {
		// 			backgroundColor: "rgba(255,255,255)",
		// 		},
		// 	},
		// },
	},

	[`& .${gridClasses.row}.odd`]: {
		// backgroundColor: "rgba(240, 240, 240,0.6)",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		"&:hover, &.Mui-hovered": {
			backgroundColor: "rgba(220,220,220,0.6)",
			cursor: "pointer",
			"@media (hover: none)": {
				backgroundColor: "rgba(255,255,255)",
			},
		},
		// "&.Mui-selected": {
		// 	backgroundColor: "#f7fafb64",
		// 	"&:hover, &.Mui-hovered": {
		// 		backgroundColor: "rgba(255,255,255)",
		// 		// Reset on touch devices, it doesn't add specificity
		// 		"@media (hover: none)": {
		// 			backgroundColor: "rgba(255,255,255)",
		// 		},
		// 	},
		// },
	},
}));
