import {
	Box,
	Typography,
	Radio,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import styles from "./EstimateDetailSection.module.scss";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "../Util/EditorToolbar";

export default function EstimateDetailProtection({
	items,
	isEditabled,
	isSettingMode,
	updateEntry,

	deleteEntry,
	sectionIndex,
}) {
	const selection = isSettingMode
		? parseInt(items[0].default_value)
		: parseInt(items[0].value);
	return (
		<Box className="center-column" width="95%">
			<Box className={styles.entry_list_container}>
				<Box width="10%" />
				<Box width="35%" className="center-row">
					<Typography variant="body2" sx={{ fontWeight: "600" }}>
						Deductible Levels
					</Typography>
				</Box>
				<Box width="35%" className="center-row">
					<Typography variant="body2" sx={{ fontWeight: "600" }}>
						Valuation Charge
					</Typography>
				</Box>
				<Box width="10%" />
			</Box>
			{items.map((value, entryIndex) => {
				let deductible = value.entry_name.split(",")[0];
				let charge = value.entry_name.split(",")[1];
				if (entryIndex !== 0 && entryIndex !== 1) {
					console.log(entryIndex, value);
					return (
						<Box
							width="100%"
							key={entryIndex}
							className={styles.entry_list_container_protection}
						>
							<Box width="10%">
								<Radio
									checked={selection === entryIndex}
									disabled={!isEditabled}
									onChange={() => {
										let updateIndex = entryIndex.toString();

										updateEntry(
											sectionIndex,
											0,
											updateIndex,
											"value"
										);
									}}
								/>
							</Box>
							<Box width="35%">
								{isEditabled && (
									<TextField
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography
														fontSize="16px"
														paddingLeft="10px"
														backgroundColor="#dce0dc"
														paddingRight="10px"
														color="#057a25"
														marginLeft={"5px"}
													>
														$
													</Typography>
												</InputAdornment>
											),
											className: styles.text_input,
										}}
										value={deductible}
										onChange={(e) => {
											updateEntry(
												sectionIndex,
												entryIndex,
												e.target.value + "," + charge,
												"entry_name"
											);
										}}
									/>
								)}
								{!isEditabled && (
									<Typography variant="body1">
										{deductible}
									</Typography>
								)}
							</Box>
							<Box width="35%">
								{isEditabled && (
									<TextField
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography
														fontSize="16px"
														paddingLeft="10px"
														backgroundColor="#dce0dc"
														paddingRight="10px"
														color="#057a25"
														marginLeft={"5px"}
													>
														$
													</Typography>
												</InputAdornment>
											),
											className: styles.text_input,
										}}
										value={charge}
										onChange={(e) => {
											updateEntry(
												sectionIndex,
												entryIndex,
												deductible +
													"," +
													e.target.value,
												"entry_name"
											);
										}}
									/>
								)}
								{!isEditabled && (
									<Typography variant="body1">
										{charge}
									</Typography>
								)}
							</Box>
							{isEditabled && (
								<IconButton
									color="remove"
									// className={styles.icon_button}
									display={isEditabled ? "auto" : "none"}
									sx={{ marginLeft: "5px" }}
									onClick={() => {
										deleteEntry(sectionIndex, entryIndex);
										updateEntry(
											sectionIndex,
											0,
											"0",
											"value"
										);
									}}
								>
									<RemoveCircleTwoToneIcon
										sx={{ fontSize: 20 }}
									/>
								</IconButton>
							)}
						</Box>
					);
				}
			})}

			{isEditabled && (
				<Box
					width="95%"
					className="center-column"
					marginBottom={"20px"}
					marginTop={"20px"}
				>
					{/* <EditorToolbar /> */}
					<ReactQuill
						theme="snow"
						style={{
							width: "100%",
						}}
						placeholder="Description"
						value={
							isSettingMode
								? items[1].default_value
								: items[1].value
						}
						onChange={(e) => {
							updateEntry(sectionIndex, 1, e, "value");
						}}
						// modules={modules}
						// formats={formats}
					/>
				</Box>
			)}

			{!isEditabled && (
				<Box
					width="100%"
					className="center-column"
					marginBottom={"20px"}
					marginTop={"20px"}
				>
					<ReactQuill
						theme="snow"
						style={{
							width: "100%",
						}}
						placeholder="Description"
						value={items[1].value}
						readOnly={true}
						modules={{ toolbar: false }}
					/>
				</Box>
			)}
		</Box>
	);
}
