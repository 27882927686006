import Title from "../../components/Title";
import { useState, useEffect, useRef } from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import styles from "./EstimateTemplate.module.scss";
import EstimateDetailSection from "../../components/Estimate/EstimateDetailSection";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import Loading from "../../components/Loading";
import ControlPointTwoToneIcon from "@mui/icons-material/ControlPointTwoTone";
import DoneGif from "../../components/DoneGif";
import Toggle from "../../components/Util/Toggle";
import ConfirmDialog from "../../components/Util/ConfirmDialog";

export default function EstimateTemplate() {
	const [isLoading, setIsLoading] = useState(true);
	const [estimate, setEstimate] = useState([]);
	const [newSectionName, setNewSectionName] = useState("");
	const [hasDone, setHasDone] = useState(false);

	const [estimateType, setEstimateType] = useState(0); // 0 means local, 1 means long distance
	const toggleSectionList = ["Local", "Long Distance"];

	const bottomRef = useRef(null);
	useEffect(() => {
		(async () => {
			await getEstimate(0);
		})();
	}, []);

	const getEstimate = async (estimateTypeTemp) => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();
		let estimateObj = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/estimate_entry/${estimateTypeTemp}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!estimateObj.ok || estimateObj.status !== 200)
			throw new Error(estimateObj.statusText);

		estimateObj = await estimateObj.json();
		let estimateEntryTemp = [];
		for (let [key, value] of Object.entries(estimateObj)) {
			if (key === "Relocation Rate") {
				estimateEntryTemp.unshift({
					section_name: key,
					entry_list: value,
				});
			} else {
				estimateEntryTemp.push({
					section_name: key,
					entry_list: value,
				});
			}
		}

		setEstimate(estimateEntryTemp);
		setIsLoading(false);
	};

	const addNewSection = () => {
		let temp = [...estimate];
		temp.push({
			section_name: newSectionName,
			entry_list: [],
		});
		setNewSectionName("");
		setEstimate(temp);
		scrollToBottom();
	};

	const addNewEntry = (sectionIndex) => {
		let temp = [...estimate];
		if (temp[sectionIndex].section_name === "Relocation Rate") {
			temp[sectionIndex].entry_list.splice(
				temp[sectionIndex].entry_list.length - 1,
				0,
				{
					id: temp[sectionIndex].entry_list.length,
					entry_name: "",
					value: "",
					default_value: "",
					section_name: temp[sectionIndex].section_name,
					type: estimateType,
				}
			);
		} else {
			temp[sectionIndex].entry_list.push({
				id: -1, // -1 means new entry
				entry_name: "",
				value: "",
				default_value: "",
				section_name: temp[sectionIndex].section_name,
				type: estimateType,
			});
		}
		setEstimate(temp);
	};

	const deleteEntry = (sectionIndex, entryIndex) => {
		let temp = [...estimate];
		temp[sectionIndex].entry_list.splice(entryIndex, 1);
		setEstimate(temp);
	};

	const updateEntry = (sectionIndex, entryIndex, value, type) => {
		let temp = [...estimate];
		if (type === "entry_name") {
			temp[sectionIndex].entry_list[entryIndex].entry_name = value;
		} else {
			temp[sectionIndex].entry_list[entryIndex].default_value = value;
		}
		setEstimate(temp);
	};

	const deleteSection = (sectionIndex) => {
		let temp = [...estimate];
		if (window.confirm("Are you sure you want to delete this section?")) {
			temp.splice(sectionIndex, 1);
			setEstimate(temp);
		}
	};

	const scrollToBottom = () => {
		bottomRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const saveEstimateTemplate = async () => {
		let jwt = await getAmplifyTokenAsync();
		let estimateObj = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/estimate_template`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					estimate: estimate,
					estimate_type: estimateType,
				}),
			}
		);
		if (!estimateObj.ok || estimateObj.status !== 200)
			throw new Error(estimateObj.statusText);

		setHasDone(true);
		setTimeout(() => {
			setHasDone(false);
		}, 2000);
	};

	const toggleOnChange = (index) => {
		setEstimateType(index);
		getEstimate(index);
	};

	if (isLoading) return <Loading />;
	return (
		<Box className="content-container-wrap center-column">
			<Box
				className={styles.content_container}
				backgroundColor="rgba(255,255,255,0.5)"
				maxWidth="lg"
			>
				<DoneGif isOpen={hasDone} />
				<Box height="10%" className="center-row" minHeight="80px">
					<Box className="center-column" paddingBottom={"10px"}>
						<Title title="Estimate Template" />
						<Toggle
							selectedIndex={estimateType}
							toggleList={toggleSectionList}
							onChange={toggleOnChange}
						/>
					</Box>
					<Box className={styles.new_section_box}>
						<Box className="center-row">
							<TextField
								placeholder="New Section Name"
								value={newSectionName}
								onChange={(e) =>
									setNewSectionName(e.target.value)
								}
							/>
							<IconButton color="confirm" onClick={addNewSection}>
								<ControlPointTwoToneIcon fontSize="20px" />
							</IconButton>
						</Box>
					</Box>
				</Box>
				<Box className="scroll-container" height="90%">
					{estimate.map((section, sectionIndex) => {
						return (
							<Box
								width="50%"
								key={sectionIndex}
								marginTop="10px"
							>
								<EstimateDetailSection
									section={section}
									isEditabled={true}
									sectionIndex={sectionIndex}
									updateEntry={updateEntry}
									addNewEntry={addNewEntry}
									deleteEntry={deleteEntry}
									deleteSection={deleteSection}
									isSettingMode={true}
								/>
							</Box>
						);
					})}
					<Box ref={bottomRef} width="100%" height="30px" />
				</Box>
				<Button
					onClick={() => saveEstimateTemplate()}
					className={styles.save_button}
					style={{
						backgroundColor: "#3b82f6",
						borderRadius: "15px",
						color: "white",
						width: "100px",
					}}
				>
					Save
				</Button>
			</Box>
		</Box>
	);
}
