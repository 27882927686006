import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ExpiredLogo({ onClick }) {
	return (
		<Box
			className="center-row"
			position="absolute"
			top="0"
			right="0"
			mt="120px"
			mr="80px"
			zIndex="100"
		>
			<img
				src={require("../assets/expired.png")}
				alt="expired"
				style={{ height: "100px" }}
			/>
		</Box>
	);
}
