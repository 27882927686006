import React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, params } from "react-router-dom";
import { Dialog, Box, Typography, Button, useTheme, Icon } from "@mui/material";
import Title from "../../components/Title";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import { dateToString } from "../../util/Util";
import Loading from "../Loading";
import MediaRoomDetail from "./MediaRoomDetail";
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';

export default function RequestDetail({ requestId }) {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState(0);
	const [stepData, setStepData] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [movingDetailFormData, setMovingDetailFormData] = useState([]);
	const [userAnswer, setUserAnswer] = useState([]);
	const [mediaList, setMediaList] = useState([]);
	const [requestUserId, setRequestUserId] = useState(null);
	const getMovingDetailSection = async () => {
		let jwt = await getAmplifyTokenAsync();
		let moving_detail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/moving_detail_section`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!moving_detail.ok || moving_detail.status !== 200)
			throw new Error(moving_detail.statusText);
		moving_detail = await moving_detail.json();
		return moving_detail;
	};

	const getMovingDetailEntry = async () => {
		let jwt = await getAmplifyTokenAsync();
		let moving_detail_entry = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/moving_detail_entry`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!moving_detail_entry.ok || moving_detail_entry.status !== 200)
			throw new Error(moving_detail_entry.statusText);
		moving_detail_entry = await moving_detail_entry.json();

		moving_detail_entry[1].unshift({
			id: -1,
			section_id: 1,
			title: "User Name",
			active: 1,
			type: "text",
			selection_options: [],
		})
		return moving_detail_entry;
	};

	const getRequestMovingDetail = async () => {
		let jwt = await getAmplifyTokenAsync();
		let request_moving_detail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_moving_detail/${requestId}`,
			{
				method: "GET",
				headers: {
					"Cotent-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!request_moving_detail.ok || request_moving_detail.status !== 200)
			throw new Error(request_moving_detail.statusText);
		request_moving_detail = await request_moving_detail.json();
		return request_moving_detail;
	};

	const getMediaList = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_media_list/${requestId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		res = await res.json();
		let resArray = [];
		for (let roomName in res) {
			let requestedMediaList = res[roomName];
			let resMediaList = [];
			for (let requestedMedia of requestedMediaList) {
				let mediaObj = {};
				mediaObj["id"] = requestedMedia.id;
				mediaObj["label"] = requestedMedia.media_label;
				mediaObj["comment"] = requestedMedia.note;
				mediaObj["uri"] = requestedMedia.local_uri;
				mediaObj["localUri"] = requestedMedia.local_uri;
				mediaObj["mediaType"] = requestedMedia.media_type;
				mediaObj["duration"] = requestedMedia.duration;
				mediaObj["s3Uri"] = requestedMedia.s3_uri;
				mediaObj["createdTime"] = requestedMedia.created_time;
				mediaObj["createdBy"] = requestedMedia.created_by;
				mediaObj["createdByName"] = requestedMedia.created_by_name;
				mediaObj["hasAudioAI"] = requestedMedia.has_audio_ai;
				mediaObj["hasVideoAI"] = requestedMedia.has_video_ai;
				resMediaList.push(mediaObj);
			}

			resArray.push({ roomLabel: roomName, medias: resMediaList });
		}
		console.log("Media List ", resArray);
		setMediaList(resArray);
	};

	const getRequestInfo = async () => {
		let jwt = await getAmplifyTokenAsync();
		let requestInfo = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request/${requestId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!requestInfo.ok || requestInfo.status !== 200)
			throw new Error(requestInfo.statusText);
		requestInfo = await requestInfo.json();
		return requestInfo;
	};

	useEffect(() => {
		(async () => {
			try {
				let movingDetailSection = await getMovingDetailSection();
				let movingDetailEntry = await getMovingDetailEntry();
				let requestMovingDetail = await getRequestMovingDetail();
				let mediaList = await getMediaList();
				let movingSectionWithEntries = [];
				let requestInfo = await getRequestInfo();
				setRequestUserId(requestInfo.user_id);
				for (let section of movingDetailSection) {
					section["entries"] = movingDetailEntry[section["id"]];
					movingSectionWithEntries.push(section);
				}
				setMovingDetailFormData(movingSectionWithEntries);

				let tempStepDate = [];
				movingSectionWithEntries.forEach((section, index) => {
					tempStepDate.push({
						page_id: index,
						form_page_id: section.id,
						title: section.section_title,
						short_title: section.short_title,
					});
				});
				setStepData(tempStepDate);
				let tempUserAnswer = [];
				for (let i = 0; i < movingSectionWithEntries.length; i++) {
					tempUserAnswer[i] = {};
					let singleFormData = movingSectionWithEntries[i];
					for (let q of singleFormData.entries) {
						tempUserAnswer[i][q.id] = requestMovingDetail[q.id];
					}
				}
				setUserAnswer(tempUserAnswer);
				setIsLoading(false);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	const openGoogleMaps = (address) => {
		const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
		window.open(googleMapsUrl, '_blank');
	};

	return (
		<Box
			className="center-column"
			height="100%"
			w="100%"
			borderRadius="20px"
			sx={{
				backgroundImage: `url(${require("../../assets/map.png")})`,
			}}
		>
			<Box
				className="center-column"
				justifyContent={"flex-start"}
				style={{
					height: "98%",
					width: "100%",
					overflow: "scroll",
				}}
			>
				<Title title="Request Detail" />

				<Box
					className="center-row"
					style={{
						marginTop: "10px",
						border: "4px solid #3b82f6",
						backgroundColor: "rgba(255,255,255,0.6)",
						borderRadius: "15px",
						boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.55)",
					}}
				>
					<Box
						className={
							currentTab === 0
								? "detail-tab-active"
								: "detail-tab"
						}
						onClick={() => {
							setCurrentTab(0);
						}}
					>
						<Typography>Detail</Typography>
					</Box>
					<Box
						className={
							currentTab === 1
								? "detail-tab-active"
								: "detail-tab"
						}
						onClick={() => {
							setCurrentTab(1);
						}}
					>
						<Typography>Media</Typography>
					</Box>
				</Box>
				{currentTab === 0 && (
					<>
						<Box
							className="center-row"
							style={{
								marginTop: "20px",
								border: "4px solid #3b82f6",
								borderRadius: "15px",
								backgroundColor: "rgba(255,255,255,0.6)",
								boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.55)",
							}}
						>
							{stepData.map((step, index) => {
								return (
									<Box
										key={index}
										className={
											currentPage === index
												? "detail-tab-active"
												: "detail-tab"
										}
										onClick={() => {
											setCurrentPage(index);
										}}
									>
										<Typography>
											{step.short_title}
										</Typography>
									</Box>
								);
							})}
						</Box>
						<Box
							width="90%"
							className="center-column"
							style={{
								backgroundColor: "rgba(255,255,255,0.8)",
								borderRadius: "30px",
								paddingTop: "20px",
								boxShadow:
									"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
							}}
						>
							{movingDetailFormData[currentPage].entries.map(
								(question, index) => {
									let res =
										userAnswer[currentPage][question.id];
									if (res === "") {
										res = "";
									} else if (question.type === "date") {
										res = dateToString(
											new Date(parseInt(res))
										).slice(0, 10);
									} else if (question.type === "select") {
										if (res === "0" || res === 0) {
											res = "";
										} else {
											res =
												question.selection_options.find(
													(option) =>
														option.id ===
														parseInt(res)
												).value;
										}
									} else if (question.type === "switch") {
										res = res === "1" ? "Yes" : "No";
									}

									return (
										<Box
											key={index}
											width="95%"
											className="center-row"
											marginBottom="30px"
										>
											<Typography
												width="30%"
												color="#9a9a9c"
											>
												{question.title}
											</Typography>
											{question.type === "address" ?
												<Box
													key={index}
													width="65%"
													className="center-row"
													marginBottom="30px"
													sx={{ position: 'relative' }} // Set the parent container to relative
												>
													<Typography
														variant="body1"
														width="100%"
														fontWeight="500"
														backgroundColor="#ededed"
														padding="10px"
														borderRadius="15px"
														boxShadow="0px 0px 7px 0px rgba(0,0,0,0.25)"
														sx={{
															cursor: 'pointer',
															'&:hover': {
																// backgroundColor: '#d4d4d4',
																boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.45)',
															},
														}}
														onClick={() => openGoogleMaps(res)}
													>
														{res}
													</Typography>
													<PublicTwoToneIcon
														sx={{
															position: 'absolute',
															top: '-5px',  // Adjust based on your padding and preference
															right: '-5px',  // Adjust based on your padding and preference
															cursor: 'pointer',
															color: '#3b82f6',
														}}
													/>
												</Box>
												:
												<Typography
													variant="body1"
													width="65%"
													fontWeight={"500"}
													backgroundColor="#ededed"
													padding="10px"
													borderRadius={"15px"}

												>
													{res}
												</Typography>
											}
										</Box>
									);

								}
							)}
						</Box>
					</>
				)}
				{currentTab === 1 && (
					<>
						{mediaList.map((room, index) => {
							return (
								<MediaRoomDetail
									key={index}
									room={room}
									requestUserId={requestUserId}
								/>
							);
						})}
					</>
				)}
			</Box>
		</Box>
	);
}
