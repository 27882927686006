import { Box, TextField, IconButton, Typography, Icon } from "@mui/material";
import styles from "./CaseChat.module.scss";
import SendIcon from "@mui/icons-material/Send";
import Loading from "../Loading";
import { useEffect, useState } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import { dateToString } from "../../util/Util";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

export default function CaseChat({ caseId, handleClose }) {
	const [isLoading, setIsLoading] = useState(true);
	const [caseChatHistory, setCaseChatHistory] = useState([]);
	const [chatMessageInput, setChatMessageInput] = useState("");
	const userId = parseInt(localStorage.getItem("userId"));

	const updateCaseViewedRecord = async (caseId) => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/update_case_comment_viewed_record/${caseId}`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
	};

	const getChatMessage = async () => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();

		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_comments_by_case_id/${caseId}`,
			{
				method: "GET",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		res = await res.json();
		setCaseChatHistory(res);
		await updateCaseViewedRecord(caseId);
	};

	const sendChatMessage = async () => {
		let jwt = await getAmplifyTokenAsync();
		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/create_case_comment`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					case_id: caseId,
					user_id: userId,
					comment: chatMessageInput,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		setChatMessageInput("");
		getChatMessage();
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			await getChatMessage();
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<Box className={styles.chat_container}>
			<Box className={styles.chat_title_container} onClick={handleClose}>
				<Box className={styles.chat_title}>Chat</Box>
				<IconButton>
					<HighlightOffTwoToneIcon color="error" />
				</IconButton>
			</Box>
			<Box className={styles.chat_content_container}>
				{caseChatHistory.map((message, index) => {
					let boxStyle = styles.message_box_container;
					if (message.user_id === userId) {
						boxStyle = styles.message_box_container_right;
					} else if (message.user_id === -1) {
						boxStyle = styles.message_box_container_system;
					} else {
						boxStyle = styles.message_box_container;
					}

					return (
						<Box className={boxStyle} key={index}>
							{message.user_id !== userId &&
								message.user_id !== -1 && (
									<Box className={styles.chat_message_user}>
										{message.user_name}
									</Box>
								)}
							<Box className={styles.chat_message}>
								{message.user_id === -1 && "---"}
								{message.comment}
								{message.user_id === -1 && "---"}
							</Box>
							<Box className={styles.chat_message_time}>
								{dateToString(new Date(message.creation_time))}
							</Box>
						</Box>
					);
				})}
			</Box>
			<Box className={styles.chat_input_container}>
				<TextField
					className={styles.chat_input}
					InputProps={{
						style: {
							padding: "20px",
						},
					}}
					value={chatMessageInput}
					onChange={(e) => {
						setChatMessageInput(e.target.value);
					}}
				/>
				<IconButton
					onClick={() => {
						sendChatMessage();
					}}
				>
					<SendIcon color="primary" />
				</IconButton>
			</Box>
		</Box>
	);
}
