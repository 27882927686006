import { Box, Typography, Badge } from "@mui/material";
import styles from "./CaseDashboardTab.module.scss";
export default function CaseDashboardTab({
	caseTabs,
	currentTab,
	setCurrentTab,
	notification,
	unviewedCaseCount,
}) {
	return (
		<Box className={styles.container}>
			{caseTabs.map((tab, index) => {
				return (
					<Box
						key={tab.id}
						sx={{
							color: "white",
							width: "19%",
							height: "90%",
							borderRadius: "10px",
							position: "relative",
						}}
					>
						<Badge
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							overlap="circular"
							badgeContent={
								notification[tab.id] ? notification[tab.id] : 0
							}
							color="confirm"
							sx={{
								position: "absolute",
								top: "5px",
								right: "5px",
							}}
						></Badge>
						<Badge
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							overlap="circular"
							badgeContent={
								unviewedCaseCount[tab.id]
									? unviewedCaseCount[tab.id]
									: 0
							}
							color="primary"
							sx={{
								position: "absolute",
								top: "5px",
								right: "20px",
							}}
						></Badge>
						<Box
							className={
								tab.id === currentTab
									? styles.tab_box_active
									: styles.tab_box
							}
							onClick={() => setCurrentTab(tab.id)}
						>
							<Typography className={styles.tab_title}>
								{tab.status}
							</Typography>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
}
