import React from "react";
import "./RequestDetailModal.css";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, params, useLocation } from "react-router-dom";
import {
	Dialog,
	Box,
	Typography,
	Button,
	useTheme,
	DialogContentText,
} from "@mui/material";
import Title from "../../components/Title";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import RequestDetail from "../../components/Request/RequestDetail";
import Loading from "../../components/Loading";
import ModalCloseBtn from "../../components/ModalCloseBtn";

export default function RequestDetailModal() {
	const [isLoading, setIsLoading] = useState(true);
	const theme = useTheme();
	const navigate = useNavigate();
	let { requestId } = useParams();

	const [requestAccountViewDetail, setRequestAccountViewDetail] = useState(
		{}
	);

	const getrequestAccountViewDetail = async () => {
		let jwt = await getAmplifyTokenAsync();
		console.log("requestId", requestId);
		let request_detail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_request_account_detail/${requestId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!request_detail.ok || request_detail.status !== 200)
			throw new Error(request_detail.statusText);
		request_detail = await request_detail.json();
		console.log("request_detail", request_detail);
		setRequestAccountViewDetail(request_detail);
		console.log("Has_viewed ", request_detail["has_viewed"]);
		if (request_detail["has_viewed"] === 0) {
			await updateRequestAccountHasViewed(request_detail.id);
		}
	};

	const updateRequestAccountHasViewed = async (request_account_id) => {
		console.log("request_account_id", request_account_id);
		let jwt = await getAmplifyTokenAsync();
		let update_request_account_has_viewed = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/update_request_account_has_viewed/${request_account_id}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (
			!update_request_account_has_viewed.ok ||
			update_request_account_has_viewed.status !== 200
		)
			throw new Error(update_request_account_has_viewed.statusText);
	};

	const acceptRequest = async () => {
		let jwt = await getAmplifyTokenAsync();
		let accept_request = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/accept_request/${requestId}`,
			{
				method: "POST",
				headers: {
					Authorization: jwt,
					"Content-Type": "application/json",
				},
			}
		);
		if (!accept_request.ok || accept_request.status !== 200) {
			throw new Error(accept_request.statusText);
		}

		accept_request = await accept_request.json();
		navigate(`/case-dashboard/case-detail/${accept_request["case_id"]}`);
	};

	useEffect(() => {
		(async () => {
			await getrequestAccountViewDetail();
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<Dialog
			key={requestId}
			open={true}
			onClose={() => navigate(-1)}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			sx={{
				"& .MuiDialog-paper": {
					position: "absolute",
					left: "2%",
					height: "90vh",
					backgroundColor: "rgba(255,255,255,0.8)",
					backdropFilter: "blur(20px)",
					borderRadius: "20px",
					boxShadow:
						requestAccountViewDetail.has_viewed === 2
							? "0px 5px 10px 2px rgba(52, 235, 100, 0.5), 0px -5px 10px 2px rgba(52, 235, 100, 0.5)"
							: "none",
				},
			}}
			maxWidth={"sm"}
			fullWidth={true}
		>
			<RequestDetail requestId={requestId} />
			{(requestAccountViewDetail.has_viewed === 0 ||
				requestAccountViewDetail.has_viewed === 1) && (
				<Button
					onClick={() => acceptRequest()}
					style={{
						position: "absolute",
						bottom: "2%",
						left: "40%",
						backgroundColor: theme.palette.primary.main,
						color: "white",
						width: "20%",
						padding: "12px 0px",
						borderRadius: "20px",
						boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
						fontSize: "16px",
						fontWeight: "600",
					}}
				>
					Accept
				</Button>
			)}
			{requestAccountViewDetail.has_viewed === 2 && (
				<Box
					style={{
						position: "absolute",
						bottom: "3%",
						width: "100%",
						color: theme.palette.confirm.main,
						fontWeight: "800",
						fontSize: "18px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography fontWeight={800} fontSize={18}>
						Accepted
					</Typography>
				</Box>
			)}
			<ModalCloseBtn onClick={() => navigate(-1)} />
		</Dialog>
	);
}
