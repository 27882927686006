import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import Loading from "../../components/Loading";
import styles from "./MediaProcess.module.scss";
import { dateToString } from "../../util/Util";
import MediaProcessTable from "../../components/MediaProcess/MediaProcessTable";
export default function MediaProcessTickets() {
	const [isLoading, setIsLoading] = useState(true);
	const [requestList, setRequestList] = useState([]);

	const navigate = useNavigate();

	const mediaProcessTableColumn = useMemo(() => [
		{
			Header: "Ticket No",
			accessor: "id",
		},
		{
			Header: "From",
			accessor: "moving_from",
		},
		{
			Header: "To",
			accessor: "moving_to",
		},

		{
			Header: "Creation Time",
			accessor: "creation_time",
		},
	]);

	const getCompletedRequestList = async () => {
		let jwt = await getAmplifyTokenAsync();
		let request_list = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/media_process_tickets/1`, // 1 means new ticket
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!request_list.ok || request_list.status !== 200)
			throw new Error(request_list.statusText);
		request_list = await request_list.json();
		setRequestList(request_list);
	};

	useEffect(() => {
		(async () => {
			await getCompletedRequestList();
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		const interval = setInterval(async () => {
			await getCompletedRequestList();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box className="content-container-wrap">
			<Box className="content-container">
				<Box height="8%" className="center-row">
					<Title title="Media Ticket" />
				</Box>
				<Box className={styles.table_container}>
					<Box
						className="center-column"
						width="100%"
						style={{
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "row",
							flexWrap: "wrap",
						}}
						marginTop="20px"
						marginBottom="20px"
					>
						<MediaProcessTable
							columns={mediaProcessTableColumn}
							data={requestList}
						/>
					</Box>
				</Box>
			</Box>

			<Outlet />
		</Box>
	);
}
