import { createTheme } from "@mui/material";

export const mainLightTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#3b82f6",
			light: "#edf4fc",
		},
		confirm: {
			main: "#22c55e",
		},
		secondary: {
			main: "#ff6600",
		},
		background: {
			default: "#f9f9f9",
		},
		warning: {
			main: "#fffc00",
		},
		remove: {
			main: "#ed3b44",
		},
		error: {
			main: "#a20f05",
		},
		text: {
			primary: "#000000",
			secondary: "#ff6600",
		},
		view: {
			main: "#FAFAFA",
		},
	},
	typography: {
		button: {
			textTransform: "none",
		},
	},
});

export const fonts = {
	defaultSize: 18,
	labelSize: 18,
	buttonSize: 15,
	welcomeButtonSize: 16,
	projectListSize: 20,
	tableSize: 16,
	menuSize: 18,
	snackBarSize: 18,
};
