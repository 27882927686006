import {
	useEffect,
	useState,
	useRef,
	forwardRef,
	useImperativeHandle,
} from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import {
	Typography,
	Box,
	Input,
	Autocomplete,
	TextField,
	Button,
	IconButton,
	Tooltip,
} from "@mui/material";
import ControlPointTwoToneIcon from "@mui/icons-material/ControlPointTwoTone";
import AddHomeWorkTwoToneIcon from "@mui/icons-material/AddHomeWorkTwoTone";
import DeleteSweepTwoToneIcon from "@mui/icons-material/DeleteSweepTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import styles from "./InventoryList.module.scss";
import Loading from "../Loading";

const InventoryItem = forwardRef((props, ref) => {
	// export default function InventoryList({
	// 	inventoryListId,
	// 	isEditabled = true,
	// 	isFold,
	// 	setIsFold,
	// }) {

	const { inventoryListId, isEditabled = true, isFold, setIsFold } = props;
	const [isLoading, setIsLoading] = useState(true);

	const [inventoryPool, setInventoryPool] = useState([]);
	const [inventoryPoolObject, setInventoryPoolObject] = useState({});
	const [inventoryCubicCheck, setInventoryCubicCheck] = useState({});
	const [requestInventoryItem, setRequestInventoryItem] = useState([]);
	const bottomRef = useRef(null);

	useImperativeHandle(
		ref,
		() => {
			return {
				getInventoryListWithItems() {
					return requestInventoryItem;
				},
			};
		},
		[requestInventoryItem]
	);

	const getInventoryPool = async () => {
		/*
    
		return Object:
		{id: 1, request_id: 1, creation_time: "2021-04-20T00:00:00", "rooms": [room1, room2, room3, ...]
		Room Object:
			room: {room_label: "room1", items: [{item_id: 1, quantity: 1}, {item_id: 2, quantity: 2}, ...]}

		 */
		let jwt = await getAmplifyTokenAsync();
		let inventory_pool = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/inventory_pool`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!inventory_pool.ok || inventory_pool.status !== 200)
			throw new Error(inventory_pool.statusText);
		inventory_pool = await inventory_pool.json();
		if (inventory_pool.length === 0) {
			let new_inventory = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/initial_inventory_pool`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
				}
			);
			if (!new_inventory.ok || new_inventory.status !== 200)
				throw new Error(new_inventory.statusText);
			inventory_pool = await new_inventory.json();
		}

		let resObj = {};
		let cubicCheckObj = {};
		for (let i in inventory_pool) {
			inventory_pool[i].label = inventory_pool[i].name;
			cubicCheckObj[inventory_pool[i].label] = inventory_pool[i];
			resObj[inventory_pool[i].id] = inventory_pool[i];
		}
		setInventoryPool(inventory_pool);
		setInventoryCubicCheck(cubicCheckObj);
		setInventoryPoolObject(resObj);
	};

	const getInventoryListWithItems = async (inventoryListId) => {
		setIsLoading(true);
		let jwt = await getAmplifyTokenAsync();

		let inventory_list = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/request_inventory_list_with_items/${inventoryListId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!inventory_list.ok || inventory_list.status !== 200)
			throw new Error(inventory_list.statusText);
		inventory_list = await inventory_list.json();
		setRequestInventoryItem(inventory_list.rooms);
	};


	const addRoom = () => {
		let newRequestInventoryItem = [...requestInventoryItem];
		newRequestInventoryItem.push({
			room_label: "",
			items: [],
		});
		setRequestInventoryItem(newRequestInventoryItem);
		bottomRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const updateRoomLabel = (roomIndex, room_label) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		newRequestInventoryItem[roomIndex].room_label = room_label;
		setRequestInventoryItem(newRequestInventoryItem);
	};

	const removeRoom = (roomIndex) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		if (newRequestInventoryItem[roomIndex].items.length !== 0) {
			alert(
				"Please remove all items in the room before removing the room"
			);
		} else {
			newRequestInventoryItem.splice(roomIndex, 1);
			setRequestInventoryItem(newRequestInventoryItem);
		}
	};

	const addItem = (roomIndex) => {
		let newRequestInventoryItem = [...requestInventoryItem];

		newRequestInventoryItem[roomIndex].items.push({
			item_id: -1,
			quantity: 1,
			item_name: "",
			cubic_ft: 0,
		});
		setRequestInventoryItem(newRequestInventoryItem);
	};

	const updateItem = (roomIndex, itemIndex, item_name) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		newRequestInventoryItem[roomIndex].items[itemIndex].item_name =
			item_name;
		if (item_name in inventoryCubicCheck) {
			newRequestInventoryItem[roomIndex].items[itemIndex].cubic_ft =
				inventoryCubicCheck[item_name].cubic_ft;
			newRequestInventoryItem[roomIndex].items[itemIndex].item_id =
				inventoryCubicCheck[item_name].id;
		} else {
			newRequestInventoryItem[roomIndex].items[itemIndex].cubic_ft = 0;
			newRequestInventoryItem[roomIndex].items[itemIndex].item_id = -1;
		}
		setRequestInventoryItem(newRequestInventoryItem);
	};

	const removeItem = (roomIndex, itemIndex) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		newRequestInventoryItem[roomIndex].items.splice(itemIndex, 1);
		setRequestInventoryItem(newRequestInventoryItem);
	};

	const updateItemQuantity = (roomIndex, itemIndex, quantity) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		quantity = quantity.replace(/^0+/, "");
		if (quantity === "") {
			quantity = 0;
		}

		newRequestInventoryItem[roomIndex].items[itemIndex].quantity = quantity;

		setRequestInventoryItem(newRequestInventoryItem);
	};

	const updateItemCubicFt = (roomIndex, itemIndex, cubic_ft) => {
		let newRequestInventoryItem = [...requestInventoryItem];
		cubic_ft = cubic_ft.replace(/^0+/, "");
		if (cubic_ft === "") {
			cubic_ft = 0;
		}
		newRequestInventoryItem[roomIndex].items[itemIndex].cubic_ft = cubic_ft;
		setRequestInventoryItem(newRequestInventoryItem);
	};


	useEffect(() => {
		(async () => {
			await getInventoryPool();

			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (inventoryListId) {
				await getInventoryListWithItems(inventoryListId);
			} else {
				setRequestInventoryItem([]);
			}
			setIsLoading(false);
		})();
	}, [inventoryListId]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box
			width="100%"
			height="100%"
			overflow="scroll"
			position="relative"
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"flex-start"}
			alignItems={"center"}
		>
			<Box className={styles.inventory_title}>
				<Box
					className="center-row"
					width="100%"
					justifyContent={"space-between"}
					paddingLeft="5%"
					paddingRight="5%"
				>
					<Typography fontWeight="700" fontSize={20}>
						Inventory List
					</Typography>

					<Box>
						{isEditabled && (
							<IconButton
								color="confirm"
								onClick={() => addRoom()}
								variant="outlined"
							>
								<Tooltip title="Add Room">
									<AddHomeWorkTwoToneIcon />
								</Tooltip>
							</IconButton>
						)}
						{isEditabled && (
							<IconButton
								color="remove"
								onClick={() => setRequestInventoryItem([])}
								variant="outlined"
							>
								<Tooltip title="Clear All">
									<DeleteSweepTwoToneIcon />
								</Tooltip>
							</IconButton>
						)}
						<IconButton
							color="primary"
							onClick={() => {
								setIsFold(!isFold);
							}}
						>
							{isFold ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
						</IconButton>
					</Box>
				</Box>
				<Box className="center-row" justifyContent={"space-between"} width="100%">
					{/* <Button
						// onClick={() => {
						// 	createNewInventoryList()
						// }}
						variant="contained"
						style={{
							marginLeft: "5%",
							borderRadius: "10px",
							boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
							fontSize: "14px",
							padding: "3px",
							paddingLeft: "3px",
							paddingRight: "3px",
							backgroundColor: "#3b82f6",
						}}
					>
						Save
					</Button> */}
					<Box
						className="center-row"
						justifyContent={"flex-end"}
						alignItems={"start"}
						paddingLeft="5%"
						paddingRight="5%"
						paddingBottom={"5px"}

					>
						<Box
							className="center-row"
							bgcolor={"#F5F5F5"}
							padding="6px"
							paddingLeft="10px"
							paddingRight="10px"
							borderRadius="20px"
							boxShadow="0px 0px 5px 0px rgba(0,0,0,0.15)"
							marginRight="10px"
						>
							<Typography
								fontSize={14}
								fontWeight="500"
								paddingRight="5px"
								color="gray"
							>
								Items:
							</Typography>
							<Typography
								fontSize={14}
								fontWeight="600"
								color="green"
							>
								{requestInventoryItem.reduce((acc, room) => {
									return (
										acc +
										room.items.reduce((acc, item) => {
											return acc + parseInt(item.quantity);
										}, 0)
									);
								}, 0)}
							</Typography>
						</Box>
						<Box
							className="center-row"
							bgcolor={"#F5F5F5"}
							padding="6px"
							borderRadius="20px"
							paddingLeft="10px"
							paddingRight="10px"
							boxShadow="0px 0px 5px 0px rgba(0,0,0,0.15)"
						>
							<Typography
								fontSize={14}
								fontWeight="500"
								paddingRight="5px"
								color="gray"
							>
								Cu Ft:
							</Typography>
							<Typography
								fontSize={14}
								fontWeight="600"
								color="green"
							>
								{requestInventoryItem.reduce((acc, room) => {
									return (
										acc +
										room.items.reduce((acc, item) => {
											return (
												acc + item.quantity * item.cubic_ft
											);
										}, 0)
									);
								}, 0)}
							</Typography>
						</Box>
					</Box>
				</Box>

			</Box>
			{!isFold &&
				requestInventoryItem.map((room, roomIndex) => {
					return (
						<Box
							key={roomIndex}
							className={styles.inventory_room_container}
						>
							{isEditabled && (
								<Box
									className="center-row"
									padding="2%"
									width="90%"
									justifyContent={"space-between"}
								>
									<TextField
										label="Room Label"
										variant="outlined"
										size="small"
										value={room.room_label}
										onChange={(e) => {
											updateRoomLabel(
												roomIndex,
												e.target.value
											);
										}}
									/>

									<Box className="center-row">
										<IconButton
											display={
												isEditabled ? "auto" : "none"
											}
											color="confirm"
											onClick={() => addItem(roomIndex)}
										>
											<ControlPointTwoToneIcon
												sx={{ fontSize: 25 }}
											/>
										</IconButton>
										<IconButton
											color="remove"
											onClick={() =>
												removeRoom(roomIndex)
											}
										>
											<RemoveCircleTwoToneIcon
												sx={{ fontSize: 25 }}
											/>
										</IconButton>
									</Box>
								</Box>
							)}
							{!isEditabled && (
								<Box className="center-row" padding="2%">
									<Typography>{room.room_label}</Typography>
								</Box>
							)}

							<Box
								className="center-row"
								width="90%"
								padding="1%"
								backgroundColor="#d6d6d6"
							>
								<Box width="15%" className="center-row">
									<Typography>QTY</Typography>
								</Box>
								<Box
									width="60%"
									borderLeft="1px solid black"
									borderRight="1px solid black"
									className="center-row"
								>
									<Typography>Item</Typography>
								</Box>
								<Box width="25%" className="center-row">
									<Typography>Cu Ft</Typography>
								</Box>
							</Box>
							{room.items.map((item, itemIndex) => {
								let bgColor =
									itemIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
								return (
									<Box
										key={itemIndex}
										className="center-row"
										alignItems="center"
										width="90%"
										paddingTop="5px"
										paddingBottom="5px"
										bgcolor={bgColor}
										height="100%"
									>
										<Box width="15%" className="center-row">
											<TextField
												sx={{
													height: "100%",
													"& fieldset": {
														border: "none",
													},
												}}
												onChange={(e) => {
													updateItemQuantity(
														roomIndex,
														itemIndex,
														e.target.value
													);
												}}
												type="number"
												disabled={!isEditabled}
												value={item.quantity}
											></TextField>
										</Box>
										<Box width="60%">
											<Autocomplete
												disabled={!isEditabled}
												freeSolo
												sx={{
													"& fieldset": {
														border: "none",
														overflow: "break-word",
													},
												}}
												renderOption={(
													props,
													option
												) => {
													return (
														<li
															{...props}
															key={option.id}
														>
															{option.name}
														</li>
													);
												}}
												onInputChange={(
													e,
													textValue
												) => {
													updateItem(
														roomIndex,
														itemIndex,
														textValue
													);
												}}
												inputValue={item.item_name}
												options={inventoryPool}
												renderInput={(params) => (
													<TextField
														{...params}
														multiline
													/>
												)}
											/>
										</Box>
										<Box
											width="25%"
											className="center-row"
											justifyContent={"space-evenly"}
										>
											<TextField
												sx={{
													"& fieldset": {
														border: "none",
													},
												}}
												onChange={(e) => {
													updateItemCubicFt(
														roomIndex,
														itemIndex,
														e.target.value
													);
												}}
												type="number"
												disabled={!isEditabled}
												value={item.cubic_ft}
											></TextField>

											{isEditabled && (
												<IconButton
													size="small"
													color="remove"
													onClick={() =>
														removeItem(
															roomIndex,
															itemIndex
														)
													}
												>
													<RemoveCircleTwoToneIcon
														sx={{ fontSize: 22 }}
													/>
												</IconButton>
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
					);
				})}
			<Box ref={bottomRef}></Box>
		</Box>
	);
});

export default InventoryItem;
