import { Box, Typography } from "@mui/material";

export default function Loading() {
	return (
		<Box className="center-column" width="100%" height="100%">
			<img
				src={require("../assets/moving-loading.gif")}
				alt="AI Moving Logo"
				style={{ height: "40%" }}
			/>
			<Typography fontSize="20px" marginTop="20px">
				Loading...
			</Typography>
		</Box>
	);
}
