import { Box } from "@mui/material";
import "./Login.css";
import ReactPlayer from "react-player";
export const component = {
	Header() {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: "120px",
					boxShadow: 2,
					borderRadius: 2,
					marginBottom: "20px",
					backgroundColor: "rgba(255,255,255,0.8)",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100vw",
						height: "100vh",
						zIndex: -1,
					}}
				>
					<video
						autoPlay
						loop
						playsInLine
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%",
						}}
						muted
					>
						<source
							src={require("../assets/landing.mp4")}
							type="video/mp4"
						/>
					</video>
				</Box>
				<h1>MoverMate</h1>
			</Box>
		);
	},
	SignUp: {},
};
