import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate, NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import "./Header.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import styles from "./Header.module.scss";
export default function MediaWorkerHeader(props) {
	const navigate = useNavigate();
	const theme = useTheme();

	const handleLogOut = async () => {
		try {
			await Auth.signOut();
			navigate("/");
			window.location.reload(false);
		} catch (exception) {
			console.error(exception);
		}
	};

	return (
		<Box
			className="center-row header-wrapper"
			justifyContent={"space-between"}
			position="sticky"
		>
			<Box className="center-row">
				<img
					src={require("../../assets/moving-loading.gif")}
					alt="AI Moving Logo"
					style={{ height: "50px" }}
				/>
				<Typography fontWeight={"600"}>
					AI Moving Media Process Center
				</Typography>
			</Box>
			<nav id={styles.sidebar}>
				<NavLink
					className={({ isActive, isPending }) =>
						isActive ? styles.nav_link_active : styles.nav_link
					}
					to="/"
				>
					Media Center
				</NavLink>
				<NavLink
					className={({ isActive, isPending }) =>
						isActive ? styles.nav_link_active : styles.nav_link
					}
					to="completed-tickets"
				>
					Completed Tickets
				</NavLink>
				{/* <NavLink to="/case-dashboard">Cases</NavLink> */}
				<Button
					onClick={handleLogOut}
					color="error"
					sx={{
						padding: "8px",
						margin: "0px",
						borderRadius: "20px",
					}}
				>
					<ExitToAppIcon />
				</Button>
			</nav>
		</Box>
	);
}
