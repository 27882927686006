import { Box, Typography } from "@mui/material";
import styles from "./Toggle.module.scss";

export default function Toggle({ selectedIndex, toggleList, onChange }) {
	return (
		<Box className={styles.toggle_container}>
			{toggleList.map((item, index) => {
				return (
					<Box
						key={index}
						className={
							index === selectedIndex
								? styles.toggle_tab_active
								: styles.toggle_tab
						}
						onClick={() => {
							onChange(index);
						}}
					>
						<Typography>{item}</Typography>
					</Box>
				);
			})}
		</Box>
	);
}
