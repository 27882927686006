import { Box } from "@mui/material";
export default function DoneGif({ isOpen }) {
	if (isOpen) {
		return (
			<Box
				position="absolute"
				top="40%"
				width="100%"
				className="center-row"
				zIndex={100}
			>
				<img
					src={require("../assets/done.gif")}
					alt="Done"
					style={{ height: "150px" }}
				/>
			</Box>
		);
	} else {
		<></>;
	}
}
