import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Box } from '@mui/material';
import ModalCloseBtn from '../ModalCloseBtn';

export default function NewInventoryConfirmDialog({ open, setOpen, switchToNewInvenotryList, inventoryDetail }) {

    console.log("Openinng ")
    const handleClose = () => {
        setOpen(false);
    };

    const handleSwithToNewInventoryList = (selection) => {
        setOpen(false);
        switchToNewInvenotryList(selection);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialog-paper": {
                    position: "absolute",
                    marginLeft: "0%",
                    width: "500px",
                    backgroundColor: "rgba(255,255,255,0.9 )",
                    borderRadius: "20px",
                    height: "200px",
                    minHeight: "200px",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                },
            }}
            maxWidth={"md"}
        >
            <ModalCloseBtn onClick={() => setOpen(false)} />
            <Box className="center-column">
                <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Detect a new Invenotry List Generated
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => handleSwithToNewInventoryList(true)}>Switch</Button>
                    <Button onClick={() => handleSwithToNewInventoryList(false)}>No</Button>
                </DialogActions>
            </Box>


        </Dialog>
    );
}