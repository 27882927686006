import React, { useState } from "react";
import {
	Box,
	IconButton,
	Typography,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import MediaDetail from "./MediaDetail";

export default function MediaRoomDetail({ room, index, requestUserId }) {
	return (
		<Box
			key={index}
			className="center-column shadow-2"
			style={{
				width: "95%",
				minWidth: "100px",
				backgroundColor: "rgba(255,255,255,0.8)",
				borderRadius: "30px",
				paddingTop: "10px",
				marginTop: "20px",
				boxShadow:
					"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
			}}
		>
			<Typography
				variant="h6"
				color="#3b82f6"
				fontWeight="700"
				marginBottom={"10px"}
			>
				{room.roomLabel}
			</Typography>
			{room.medias.map((media, mediaIndex) => {
				return (
					<MediaDetail
						media={media}
						requestUserId={requestUserId}
						key={mediaIndex}
					/>
				);
				// let mediaType = getMeidaType(media.s3Uri);
				// let hasAIInventory = media.hasAIInventory;
				// let border = "none";
				// if (media.createdBy !== requestUserId) {
				// 	border = "5px solid #3b82f6";
				// }

				// if (mediaType === "heic") {
				// 	convertHeicImage(media.s3Uri, media.id);
				// 	media.s3Uri = require("../../assets/moving-loading.gif");
				// }

				// return (
				// 	<Box
				// 		key={media.id}
				// 		className="center-column"
				// 		style={{
				// 			width: "95%",
				// 			marginBottom: "20px",
				// 			position: "relative",
				// 			borderRadius: "20px",
				// 			overflow: "hidden",
				// 			boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
				// 		}}
				// 		border={border}
				// 	>
				// 		<Box
				// 			position="absolute"
				// 			top="1%"
				// 			right="1%"
				// 			backgroundColor="rgba(255,255,255,0.9)"
				// 			boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
				// 			padding="8px"
				// 			borderRadius="10px 20px 10px 10px"
				// 			height="45px"
				// 		>
				// 			<Typography fontSize="10px">
				// 				{media.createdByName}
				// 			</Typography>
				// 			<Typography
				// 				variant="body1"
				// 				color="#3b82f6"
				// 				fontWeight="700"
				// 				fontSize="10px"
				// 			>
				// 				{dateTimeAgo(new Date(media.createdTime))}
				// 			</Typography>
				// 		</Box>
				// 		{media.label && (
				// 			<Box
				// 				// variant="body1"
				// 				position="absolute"
				// 				top="1%"
				// 				left="2%"
				// 				padding="2%"
				// 				borderRadius="20px 10px 10px 10px"
				// 				backgroundColor="rgba(255,255,255,0.8)"
				// 				boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
				// 			>
				// 				<Typography
				// 					color="black"
				// 					fontWeight={"800"}
				// 					fontSize={"14px"}
				// 				>
				// 					{media.label}
				// 				</Typography>
				// 			</Box>
				// 		)}
				// 		{media.mediaType === "video" && (
				// 			<Box
				// 				// variant="body1"
				// 				position="absolute"
				// 				top="calc(3% + 45px)"
				// 				right="1%"
				// 				backgroundColor="rgba(255,255,255,0.9)"
				// 				boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
				// 				borderRadius="10px"
				// 				zIndex={20}
				// 				height={40}
				// 				width={40}
				// 				className="center-row"
				// 			>
				// 				{hasAIInventory === -1 && (
				// 					<Tooltip title="Let AI Generate Inventory List">
				// 						<IconButton
				// 							color="primary"
				// 							onClick={() => {
				// 								hasAIInventory = 0;
				// 								// ai_generate_inventory_list(
				// 								// 	media.id
				// 								// );
				// 							}}
				// 						>
				// 							<AssignmentRoundedIcon
				// 								sx={{ fontSize: 20 }}
				// 							/>
				// 						</IconButton>
				// 					</Tooltip>
				// 				)}
				// 				{hasAIInventory === 0 && (
				// 					<CircularProgress size={20} />
				// 				)}
				// 			</Box>
				// 		)}
				// 		{media.mediaType === "photo" && (
				// 			<img
				// 				src={media.s3Uri}
				// 				style={{
				// 					width: "100%",
				// 					height: "500px",
				// 				}}
				// 				id={media.id}
				// 				alt={media.label}
				// 			/>
				// 		)}

				// 		{media.mediaType === "video" && (
				// 			<ReactPlayer
				// 				url={media.s3Uri}
				// 				height="500px"
				// 				width="100%"
				// 				controls
				// 			/>
				// 		)}

				// 		{media.comment && (
				// 			<Box
				// 				border="1px solid #cfd0d1"
				// 				width="100%"
				// 				padding="5px"
				// 				backgroundColor="#e8e9eb"
				// 			>
				// 				<Typography
				// 					paddingLeft="10px"
				// 					variant="body1"
				// 					color="#3b82f6"
				// 					fontWeight="500"
				// 					padding="5px"
				// 					fontSize="14px"
				// 				>
				// 					{media.comment}
				// 				</Typography>
				// 			</Box>
				// 		)}
				// 	</Box>
				// );
			})}
		</Box>
	);
}
