import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MediaWorkerHeader from "../components/Template/MediaWorkerHeader";

export default function MediaWorkerRoot() {
	return (
		<Box
			height="100vh"
			w="100vw"
			sx={{
				backgroundImage: `url(${require("../assets/bg2.jpeg")})`,
				backgroundSize: "cover",
			}}
			overflow="scroll"
		>
			<MediaWorkerHeader />

			<Box
				style={{
					height: "85vh",
					zIndex: 1,
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
}
