import Title from "../../components/Title";
import {
	Box,
	IconButton,
	TextField,
	Typography,
	InputAdornment,
	Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useRef } from "react";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import styles from "./Settings.module.scss";
import ControlPointTwoToneIcon from "@mui/icons-material/ControlPointTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import VerticalAlignBottomTwoToneIcon from "@mui/icons-material/VerticalAlignBottomTwoTone";
import { getAccountId } from "../../util/Util";
import DoneGif from "../../components/DoneGif";
export default function InventoryItemManagement() {
	const bottomRef = useRef(null);
	const [inventoryItems, setInventoryItems] = useState([]);
	const [renderItems, setRenderItems] = useState([]);
	const [accountId, setAccountId] = useState();
	const [searchText, setSearchText] = useState("");
	const [editItemId, setEditItemId] = useState(-2); // -2 means no item is being edited . Item Index from list.
	const [showAllToggle, setShowAllToggle] = useState(true);
	const [hasDone, setHasDone] = useState(false);

	useEffect(() => {
		(async () => {
			setAccountId(await getAccountId());
			await getInventoryItems();
		})();
	}, []);

	const scrollToBottom = () => {
		bottomRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	const getInventoryItems = async () => {
		let jwt = await getAmplifyTokenAsync();
		let inventoryItems = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/inventory_pool`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!inventoryItems.ok || inventoryItems.status !== 200)
			throw new Error(inventoryItems.statusText);
		inventoryItems = await inventoryItems.json();
		console.log("inventoryItems!!!!", inventoryItems);
		if (inventoryItems.length === 0) {
			let new_inventory = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/initial_inventory_pool`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
				}
			);
			if (!new_inventory.ok || new_inventory.status !== 200)
				throw new Error(new_inventory.statusText);
			inventoryItems = await new_inventory.json();
		}
		console.log("inventoryItems", inventoryItems);
		inventoryItems = sortInventoryItems(inventoryItems);

		setInventoryItems(inventoryItems);
		setRenderItems(inventoryItems);
	};

	const sortInventoryItems = (items) => {
		let newItems = items.sort((a, b) => {
			if (a.name > b.name) {
				return 1;
			}
			if (a.name < b.name) {
				return -1;
			}
			return 0;
		});
		return newItems;
	};



	const addNewItem = () => {
		let temp = [...inventoryItems];
		temp.push({
			id: -1, // -1 means the new item
			name: "",
			cubic_ft: 0,
			account_id: accountId,
		});
		setSearchText("");
		setInventoryItems(temp);
		setRenderItems(temp);
		setEditItemId(-1);
		setTimeout(() => {
			scrollToBottom();
		}, 500);

	};

	const removeItem = async (itemIndex) => {
		let item = inventoryItems[itemIndex];
		let id = item.id;
		console.log("id", id);
		if (id !== -1) {
			let jwt = await getAmplifyTokenAsync();

			let inventoryItems = await fetch(
				`${process.env.REACT_APP_AI_MOVING_API}/inventory_item/${id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwt,
					},
				}
			);
			if (!inventoryItems.ok || inventoryItems.status !== 200)
				throw new Error(inventoryItems.statusText);
		}

		let temp = [...inventoryItems];
		temp.splice(itemIndex, 1);
		setSearchText("");
		setInventoryItems(temp);
		setRenderItems(temp);
		setHasDone(true);
		setTimeout(() => {
			setHasDone(false);
		}, 2000);
	};

	const updateItemName = async (id, value) => {
		let temp_render = [...renderItems];
		let temp = [...inventoryItems];
		let itemIndex = temp.findIndex((item) => item.id === id); // Find index in the main inventory
		if (itemIndex !== -1) {
			temp[itemIndex].name = value; // Update the main inventory
		}
		let renderItemIndex = temp_render.findIndex((item) => item.id === id); // Find index in the rendered items
		if (renderItemIndex !== -1) {
			temp_render[renderItemIndex].name = value; // Update the rendered items
		}
		setInventoryItems(temp);
		setRenderItems(temp_render);
	};

	const updateItemCubicFt = async (id, value) => {
		let temp_render = [...renderItems];
		let temp = [...inventoryItems];
		let itemIndex = temp.findIndex((item) => item.id === id); // Find index in the main inventory
		if (itemIndex !== -1) {
			temp[itemIndex].cubic_ft = value; // Update the main inventory
		}
		let renderItemIndex = temp_render.findIndex((item) => item.id === id); // Find index in the rendered items
		if (renderItemIndex !== -1) {
			temp_render[renderItemIndex].cubic_ft = value; // Update the rendered items
		}
		setInventoryItems(temp);
		setRenderItems(temp_render);
	};



	const saveItem = async (itemIndex) => {
		let item = inventoryItems[itemIndex];
		let jwt = await getAmplifyTokenAsync();

		let res = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/inventory_item`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
				body: JSON.stringify({
					id: item.id,
					name: item.name,
					cubic_ft: item.cubic_ft,
					account_id: item.account_id,
				}),
			}
		);
		if (!res.ok || res.status !== 200) throw new Error(res.statusText);
		let data = await res.json();
		let temp = [...inventoryItems];
		temp[itemIndex].id = data.id;
		let sortedTemp = sortInventoryItems(temp);
		setSearchText("");
		setInventoryItems(sortedTemp);
		setRenderItems(sortedTemp);
		setHasDone(true);
		setTimeout(() => {
			setHasDone(false);
		}, 2000);
	};

	const handleSearch = (value) => {
		setSearchText(value);
		let temp = [...inventoryItems];
		if (value === "") {
			setRenderItems(temp);
		} else {
			temp = temp.filter((item) => {
				return item.name.toLowerCase().includes(value.toLowerCase());
			});
			setRenderItems(temp);
		}
	};

	const handleShowAllCheckBox = (value) => {
		let temp = [...renderItems];
		if (showAllToggle) {
			temp = temp.filter((item) => {
				return item.account_id === accountId;
			});
			setRenderItems(temp);
		} else {
			handleSearch(searchText);
		}
		setShowAllToggle(!showAllToggle);
	};
	return (
		<Box className="content-container-wrap">
			<Box
				className={styles.content_container}
			// className="content-container"
			>
				<Box height="15%" className="center-column" minHeight={"120px"}>
					<Title title="Inventory Item Management" />
					<Box className={styles.header_container}>
						<TextField
							placeholder="Search"
							sx={{
								width: "400px",
								fontSize: "20px",
								// marginTop: "10px",
								marginRight: "20px",
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							value={searchText}
							onChange={(e) => handleSearch(e.target.value)}
						/>
						{editItemId === -2 && (
							<IconButton onClick={addNewItem}>
								<ControlPointTwoToneIcon
									sx={{ fontSize: 25, color: "#228a28" }}
								/>
							</IconButton>
						)}
						{/* <Box className={styles.show_all_checkbox}>
							<Typography>Show All</Typography>
							<Checkbox
								checked={showAllToggle}
								onChange={() => {
									handleShowAllCheckBox();
								}}
							/>
						</Box> */}
						<IconButton onClick={scrollToBottom}>
							<VerticalAlignBottomTwoToneIcon
								color="primary"
								sx={{ fontSize: 25 }}
							/>
						</IconButton>
					</Box>
				</Box>
				<Box className="scroll-container" height="80%">
					{renderItems.map((inventoryItem, index) => {
						let isCenterItem = inventoryItem.account_id === 0;
						return (
							<Box
								key={index}
								className={
									isCenterItem
										? styles.item_container
										: styles.item_container_editable
								}
							>
								{editItemId === inventoryItem.id ? (
									<TextField
										disabled={isCenterItem}
										sx={{
											width: "80%",
											marginRight: "10px",
										}}
										value={inventoryItem.name}
										onChange={(e) =>
											updateItemName(
												inventoryItem.id,
												e.target.value
											)
										}
									/>
								) : (
									<Typography width="80%">
										{inventoryItem.name}
									</Typography>
								)}
								{editItemId === inventoryItem.id ? (
									<TextField
										disabled={isCenterItem}
										sx={{ width: "15%" }}
										value={inventoryItem.cubic_ft}
										onChange={(e) =>
											updateItemCubicFt(
												inventoryItem.id,
												e.target.value
											)
										}
									/>
								) : (
									<Typography width="15%">
										{inventoryItem.cubic_ft}
									</Typography>
								)}

								{editItemId === inventoryItem.id && (
									<IconButton
										onClick={() => {
											removeItem(index);
											setEditItemId(-2);
										}}
									>
										<RemoveCircleTwoToneIcon
											color="remove"
											sx={{ fontSize: 20 }}
										/>
									</IconButton>
								)}
								{editItemId === inventoryItem.id && (
									<IconButton
										onClick={() => {
											saveItem(index);
											setEditItemId(-2);
										}}
									>
										<SaveTwoToneIcon
											color="confirm"
											sx={{ fontSize: 20 }}
										/>
									</IconButton>
								)}
								{/* editItemId equal to -2 means no selected item now */}
								{editItemId === -2 && !isCenterItem && (
									<IconButton
										onClick={() => {
											setEditItemId(inventoryItem.id);
										}}
									>
										<EditTwoToneIcon
											color="primary"
											sx={{ fontSize: 20 }}
										/>
									</IconButton>
								)}
							</Box>
						);
					})}
					<Box ref={bottomRef} width="100%" />
				</Box>
				<DoneGif isOpen={hasDone} />
			</Box>
		</Box>
	);
}
