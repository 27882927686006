import React, { useState, useEffect } from "react";
import {
	Box,
	IconButton,
	Typography,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import heic2any from "heic2any";
import ReactPlayer from "react-player";
import { getAmplifyTokenAsync } from "../../util/Amplify";

import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import { dateTimeAgo } from "../../util/Util";

export default function MediaDetail({ media, requestUserId }) {
	useEffect(() => {
		let mediaType = getMeidaType(media.s3Uri);
		if (media.createdBy !== requestUserId) {
			setBorder("2px solid #3b82f6");
		}
		if (mediaType === "heic") {
			convertHeicImage(media.s3Uri, media.id);
			media.s3Uri = require("../../assets/moving-loading.gif");
		}
	}, []);
	const [border, setBorder] = useState("none");
	const [hasAudioAI, setHasAudioAI] = useState(media.hasAudioAI);
	const [hasVideoAI, setHasVideoAI] = useState(media.hasVideoAI);

	const getMeidaType = (s3Uri) => {
		let mediaType = s3Uri
			.split("/")[4]
			.split(".")[1]
			.split("?")[0]
			.toLowerCase();

		return mediaType;
	};

	const convertHeicImage = (s3Uri, mediaIndex) => {
		fetch(s3Uri)
			.then((res) => res.blob())
			.then((blob) => {
				heic2any({
					blob,
					toType: "image/jpeg",
					quality: 1,
				}).then((conversionResult) => {
					let url = URL.createObjectURL(conversionResult);
					document.getElementById(mediaIndex).src = url;
				});
			});
	};

	const audio_ai_generate_inventory_list = async (mediaId) => {
		setHasAudioAI(0);
		let jwt = await getAmplifyTokenAsync();
		let result = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/ai/generate_inventory_from_video/${mediaId}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!result.ok || result.status !== 200)
			throw new Error(result.statusText);
		result = await result.json();
		if (result.status !== "success") throw new Error(result.message);
		setHasAudioAI(1);
	};

	const video_ai_generate_inventory_list = async (mediaId) => {
		setHasVideoAI(0);
		let jwt = await getAmplifyTokenAsync();
		let result = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/ai/generate_inventory_from_video_google/${mediaId}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!result.ok || result.status !== 200)
			throw new Error(result.statusText);
		setHasVideoAI(1);
	};

	return (
		<Box
			key={media.id}
			className="center-column"
			style={{
				width: "95%",
				marginBottom: "20px",
				position: "relative",
				borderRadius: "20px",
				overflow: "hidden",
				boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
			}}
		>
			<Box
				position="absolute"
				top="1%"
				right="1%"
				backgroundColor="rgba(255,255,255,0.9)"
				boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
				padding="8px"
				borderRadius="10px 20px 10px 10px"
				height="45px"
				border={border}
			>
				<Typography fontSize="10px">{media.createdByName}</Typography>
				<Typography
					variant="body1"
					color="#3b82f6"
					fontWeight="700"
					fontSize="10px"
				>
					{dateTimeAgo(new Date(media.createdTime))}
				</Typography>
			</Box>
			{media.label && (
				<Box
					// variant="body1"
					position="absolute"
					top="1%"
					left="2%"
					padding="2%"
					borderRadius="20px 10px 10px 10px"
					backgroundColor="rgba(255,255,255,0.8)"
					boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
				>
					<Typography
						color="black"
						fontWeight={"800"}
						fontSize={"14px"}
					>
						{media.label}
					</Typography>
				</Box>
			)}
			{media.mediaType === "video" &&
				media.createdBy !== requestUserId && (
					<Box
						position="absolute"
						right="1%"
						top="calc(3% + 45px)"
						zIndex={100}
					>
						{(hasAudioAI === 0 || hasAudioAI) && <Box
							// variant="body1"

							backgroundColor="rgba(255,255,255,0.9)"
							boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
							borderRadius="10px"
							zIndex={100}
							height={36}
							width={36}
							className="center-row"
						>
							{(hasAudioAI === -1 || hasAudioAI === 1) && (
								<Tooltip title="Let Audio AI Generate Inventory List">
									<IconButton
										color="primary"
										onClick={() => {
											audio_ai_generate_inventory_list(
												media.id
											);
										}}
									>
										<SpeakerNotesIcon
											sx={{ fontSize: 18 }}
										/>
									</IconButton>
								</Tooltip>
							)}
							{hasAudioAI === 0 && (
								<Tooltip title="Audio Invenotry List Generating in progress ...">
									<CircularProgress size={18} />
								</Tooltip>
							)}
							{/* {hasAudioAI === 1 && (
								<Tooltip title="Audio Invenotry List Generated!">
									<AssignmentTurnedInRoundedIcon
										size={18}
										color="success"
									/>
								</Tooltip>
							)} */}
						</Box>
						}
						{/* <Box
							// variant="body1"
							marginTop="10px"
							backgroundColor="rgba(255,255,255,0.9)"
							boxShadow={"0px 0px 10px 0px rgba(0,0,0,0.15)"}
							borderRadius="10px"
							zIndex={100}
							height={36}
							width={36}
							className="center-column"
						>
							{hasVideoAI === -1 && (
								<Tooltip title="Let Video AI Generate Inventory List">
									<IconButton
										color="primary"
										onClick={() => {
											video_ai_generate_inventory_list(
												media.id
											);
										}}
									>
										<MissedVideoCallIcon
											sx={{ fontSize: 18 }}
										/>
									</IconButton>
								</Tooltip>
							)}
							{hasVideoAI === 0 && (
								<Tooltip title="Video Invenotry List Generating in progress ...">
									<CircularProgress size={18} />
								</Tooltip>
							)}
							{hasVideoAI === 1 && (
								<Tooltip title="Video Invenotry List Generated!">
									<AssignmentTurnedInRoundedIcon
										size={18}
										color="success"
									/>
								</Tooltip>
							)}
						</Box> */}
					</Box>
				)}
			{media.mediaType === "photo" && (
				<img
					src={media.s3Uri}
					style={{
						width: "100%",
						height: "500px",
					}}
					id={media.id}
					alt={media.label}
				/>
			)}

			{media.mediaType === "video" && (
				<ReactPlayer
					url={media.s3Uri}
					height="500px"
					width="100%"
					controls
				/>
			)}

			{media.comment && (
				<Box
					border="1px solid #cfd0d1"
					width="100%"
					padding="5px"
					backgroundColor="#e8e9eb"
				>
					<Typography
						paddingLeft="10px"
						variant="body1"
						color="#3b82f6"
						fontWeight="500"
						padding="5px"
						fontSize="14px"
					>
						{media.comment}
					</Typography>
				</Box>
			)}
		</Box>
	);
}
