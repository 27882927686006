import React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, params } from "react-router-dom";
import {
	Dialog,
	DialogTitle,
	Modal,
	Box,
	Button,
	IconButton,
	Badge,
} from "@mui/material";
import { useTheme } from "@mui/material";
import RequestDetail from "../../components/Request/RequestDetail";
import { getAmplifyTokenAsync } from "../../util/Amplify";
import EstimateDetail from "../../components/Estimate/EstimateDetail";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import CaseChat from "../../components/Case/CaseChat";
import ModalCloseBtn from "../../components/ModalCloseBtn";
import ExpiredLogo from "../../components/Expired";
import CaseDetailAssign from "./CaseDetailAssign";

export default function CaseDetailModal() {
	const [isLoading, setIsLoading] = useState(true);
	const modalRef = useRef();
	const navigate = useNavigate();
	const theme = useTheme();
	let { caseId } = useParams();

	const [caseDetail, setCaseDetail] = useState({});
	const [showChat, setShowChat] = useState(false);

	const [chatModalVisible, setChatModalVisible] = useState(false);
	const [assignModalVisible, setAssignModalVisible] = useState(false);

	const getCaseDetail = async () => {
		let jwt = await getAmplifyTokenAsync();
		let case_detail = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/get_case_by_id/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!case_detail.ok || case_detail.status !== 200)
			throw new Error(case_detail.statusText);
		case_detail = await case_detail.json();
		setCaseDetail(case_detail);
	};

	const updateCompanyViewed = async () => {
		let jwt = await getAmplifyTokenAsync();
		let update_company_viewed = await fetch(
			`${process.env.REACT_APP_AI_MOVING_API}/case/update_company_viewed/${caseId}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwt,
				},
			}
		);
		if (!update_company_viewed.ok || update_company_viewed.status !== 200)
			throw new Error(update_company_viewed.statusText);
		console.log("Update!");
	};

	useEffect(() => {
		(async () => {
			await getCaseDetail();
			await updateCompanyViewed();
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) return null;

	return (
		<Dialog
			open={true}
			onClose={() => navigate("/case-dashboard")}
			// scroll="paper"
			// aria-labelledby="scroll-dialog-title"
			// aria-describedby="scroll-dialog-description"
			sx={{
				"& .MuiDialog-paper": {
					position: "absolute",
					marginLeft: "3%",
					marginRight: "3%",
					backgroundColor: "rgba(255,255,255,0.6)",
					borderRadius: "20px",
					height: "96vh",
					flexDirection: "row",
					display: "flex",
					justifyContent: "space-evenly",
					alignItems: "center",
				},
			}}
			fullWidth={true}
			maxWidth={"xl"}
		>
			{caseDetail.request_id && caseDetail.status === 4 && (
				<CaseDetailAssign
					hasOpenModal={assignModalVisible}
					setHasOpenModal={setAssignModalVisible}
					caseId={caseId}
					requestId={caseDetail.request_id}
				/>
			)}

			<ModalCloseBtn onClick={() => navigate("/case-dashboard")} />
			<Box
				sx={{
					width: "48%",
					height: "95%",
					backgroundColor: "rgba(255,255,255,0.8)",
					borderRadius: "20px",
				}}
			>
				<RequestDetail requestId={caseDetail.request_id} />
			</Box>
			<Box
				sx={{
					width: "48%",
					height: "95%",
					backgroundColor: "rgba(255,255,255,0.8)",
					backdropFilter: "blur(2px)",
					borderRadius: "20px",
				}}
			>
				<EstimateDetail caseId={caseId} />
			</Box>
			<Badge
				sx={{
					position: "absolute",
					bottom: "5%",
					right: "5%",
					color: "white",
				}}
				badgeContent={caseDetail.message_notification}
				color="confirm"
			>
				<IconButton
					onClick={() => {
						getCaseDetail();
						setShowChat(!showChat);
					}}
					sx={{
						fontSize: "30px",
						backgroundColor: "rgba(196, 242, 199, 0.8)",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
						zIndex: "4",
						"&:hover": {
							backgroundColor: "rgba(255,255,255,0.8)",
						},
					}}
				>
					<ForumTwoToneIcon color="confirm" fontSize="30px" />
				</IconButton>
			</Badge>

			{showChat && (
				<Box
					position="absolute"
					width="46%"
					left="2%"
					bottom="2%"
					height="82%"
					zIndex="3"
					backgroundColor="rgba(255,255,255,0.4)"
					sx={{
						backdropFilter: "blur(10px)",
					}}
					borderRadius="20px"
				>
					<CaseChat
						caseId={caseId}
						handleClose={() => {
							setShowChat(false);
						}}
					/>
				</Box>
			)}
		</Dialog>
	);
}
