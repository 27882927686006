import { Auth } from "aws-amplify";

export function getAmplifyToken(callback, tokenType = "access") {
  Auth.currentSession().then((res) => {
    let accessToken = res.getAccessToken();
    let idToken = res.getIdToken();
    let refreshToken = res.getRefreshToken();
    let jwt;
    if (tokenType === "id") jwt = idToken.getJwtToken();
    else if (tokenType === "access") jwt = accessToken.getJwtToken();
    else if (tokenType === "refresh") jwt = refreshToken.getToken();
    //You can print them to see the full objects
    // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
    // console.log(`myIdToken ${JSON.stringify(idToken)}`)
    // console.log(`myJwt: ${jwt}`)
    //callback(jwt)
    callback(jwt);
  });
}

export async function getAmplifyTokenAsync(tokenType = "access") {
  let data = await Auth.currentSession();
  let accessToken = data.getAccessToken();
  let idToekn = data.getIdToken();
  let refreshToken = data.getRefreshToken();
  let jwt;
  if (tokenType === "access") {
    jwt = accessToken.getJwtToken();
  } else if (tokenType === "id") {
    jwt = idToekn.getJwtToken();
  } else if (tokenType === "refresh") {
    jwt = refreshToken.getToken();
  }
  return jwt;
}
