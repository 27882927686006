import { useTable, useSortBy, usePagination } from "react-table";
import { Box, Typography, Button, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dateToString } from "../../util/Util";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
export default function CaseTable({ columns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		nextPage,
		previousPage,
		setPageIndex,
	} = useTable(
		{ columns, data, autoResetSortBy: false, autoResetPage: false },
		useSortBy,
		usePagination
	);
	const navigate = useNavigate();

	return (
		<Box className="center-column" width="100%">
			{data.length !== 0 && (
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									let title = column.render("Header");
									if (title === "Notification") {
										title = "💬";
									}
									return (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
										>
											{title}
											<span>
												{column.isSorted
													? column.isSortedDesc
														? " ▾"
														: " ▴"
													: ""}
											</span>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							let classNameSelected = "";
							if (row.original.has_viewed === 0) {
								classNameSelected = "new_tr";
							}
							return (
								<tr
									{...row.getRowProps()}
									onClick={() => {
										navigate(
											`/case-dashboard/case-detail/${row.original.id}`
										);
									}}
									className={classNameSelected}
								>
									{row.cells.map((cell) => {
										if (
											cell.column.id ===
											"request.moving_date"
										) {
											return (
												<td {...cell.getCellProps()}>
													{dateToString(
														new Date(cell.value)
													).slice(0, 10)}
												</td>
											);
										} else if (
											cell.column.id ===
												"creation_time" ||
											cell.column.id === "updated_time"
										) {
											return (
												<td {...cell.getCellProps()}>
													{dateToString(
														new Date(cell.value)
													)}
												</td>
											);
										} else if (
											cell.column.id === "notification"
										) {
											return (
												<td
													{...cell.getCellProps()}
													style={{
														textAlign: "center",
													}}
												>
													<Badge
														label={cell.value}
														color="primary"
													></Badge>
												</td>
											);
										} else if (
											cell.column.id ===
											"has_company_viewed"
										) {
											return (
												<td
													{...cell.getCellProps()}
													style={{
														textAlign: "center",
													}}
												>
													{cell.value === 0 ? (
														<NotificationsActiveTwoToneIcon color="primary" />
													) : (
														""
													)}
												</td>
											);
										} else {
											return (
												<td {...cell.getCellProps()}>
													{cell.render("Cell")}
												</td>
											);
										}
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
			<Box className="center-row" marginTop="20px">
				<Button
					onClick={() => previousPage()}
					style={{
						marginRight: "20px",
						backgroundColor: "#3b82f6",
						borderRadius: "5px",
						color: "#F4F4F4",
						padding: "5px 10px",
						width: "100px",
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
					}}
				>
					<Typography fontSize="15px"> Previous </Typography>
				</Button>
				<Button
					onClick={() => nextPage()}
					style={{
						marginRight: "20px",
						backgroundColor: "#3b82f6",
						borderRadius: "5px",
						color: "#F4F4F4",
						padding: "5px 10px",
						width: "100px",
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
					}}
				>
					<Typography fontSize="15px"> Next </Typography>
				</Button>
			</Box>
		</Box>
	);
}
